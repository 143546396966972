// ** React
import { useState } from 'react';

// ** MUI
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';

// ** React Router DOM
import { useParams } from 'react-router-dom';

// ** Formik
import { Formik } from 'formik';

// ** Yup
import * as Yup from 'yup';

// ** Lodash
import isEmpty from 'lodash/isEmpty';

// ** Context
import { useAuth } from 'src/@core/context/auth-context';

// ** Assets
import LogoImg from 'src/assets/svg/logo.svg';
import PasswordVisible from 'src/assets/svg/password-visible.svg';
import PasswordInvisible from 'src/assets/svg/password-invisible.svg';

const classes = {
  root: {
    backgroundColor: 'yellow',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  wrapper: {
    flexGrow: 1,
    maxWidth: {
      lg: 390,
    },
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    height: {
      xs: '100%',
      lg: 'auto',
    },
    backgroundColor: 'white',
    padding: {
      xs: '24px',
      lg: '32px',
    },
    borderBottomLeftRadius: {
      lg: '16px',
    },
    borderBottomRightRadius: {
      lg: '16px',
    },
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
  title: {
    padding: 0,
    margin: 0,
    marginBottom: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '28px',
  },
  formControl: {
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: 'border',
    borderStyle: 'solid',
  },
  formControlFirst: {
    borderTopWidth: 1,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  formControlLast: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  formControlErrors: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  formAdornment: {
    marginTop: '-14px',
  },
};

const PasswordReset = () => {
  // ** Hooks
  const params = useParams();
  const { resetPasswordConfirm } = useAuth();

  // ** States
  const [passwordType, setPasswordType] = useState('password');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // ** Handlers
  const togglePasswordType = () => setPasswordType(passwordType === 'password' ? 'text' : 'password');

  // ** Formik
  const initialValues = {
    password: '',
    newPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Вы должны ввести пароль.'),
    newPassword: Yup.string()
      .required('Вы должны повторить пароль.')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Пароли не совпадают.'),
      }),
  });

  const _handleSubmit = async (values) => {
    try {
      await resetPasswordConfirm({
        uid: params.uid,
        token: params.token,
        newPassword: values.password,
      });
      setSuccess('Пароль успешно изменен!');
    } catch (error) {
      setError(error.response);
    }
  };

  const PasswordTypeSwitcher = () => {
    return (
      <IconButton onClick={togglePasswordType} size={'small'} disableRipple>
        <img alt={''} src={passwordType === 'password' ? PasswordInvisible : PasswordVisible} />
      </IconButton>
    );
  };

  return (
    <Box sx={classes.root}>
      <Grid sx={classes.wrapper} container direction={'column'}>
        <Grid item xs={true} sx={classes.logo}>
          <Box>
            <img src={LogoImg} alt={''} />
          </Box>
        </Grid>
        <Grid item xs={true} container>
          <Grid item xs={12}>
            <Box sx={classes.loginBox}>
              {success ? (
                <Typography>{success}</Typography>
              ) : (
                <Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={validationSchema}>
                  {(props) => {
                    const { values, errors, touched, handleChange, handleBlur, handleSubmit, dirty } = props;

                    return (
                      <form onSubmit={handleSubmit}>
                        <Typography variant={'h5'} component={'h5'} sx={classes.title}>
                          Изменение пароля
                        </Typography>
                        <FormControl fullWidth sx={{ ...classes.formControl, ...classes.formControlFirst }}>
                          <TextField
                            type={passwordType}
                            id={'password'}
                            name={'password'}
                            label={'Введите новый пароль'}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete={'off'}
                            variant={'standard'}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment sx={classes.formAdornment} position={'end'}>
                                  <PasswordTypeSwitcher />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={{ ...classes.formControl, ...classes.formControlLast }}>
                          <TextField
                            type={passwordType}
                            id={'newPassword'}
                            name={'newPassword'}
                            label={'Повторите новый пароль'}
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete={'off'}
                            variant={'standard'}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: (
                                <InputAdornment sx={classes.formAdornment} position={'end'}>
                                  <PasswordTypeSwitcher />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth sx={classes.formControlErrors}>
                          {!isEmpty(errors)
                            ? Object.keys(errors).map((key, index) => {
                                const err = errors[key];
                                return touched[key] ? (
                                  <FormHelperText error={true} key={index}>
                                    {err}
                                  </FormHelperText>
                                ) : null;
                              })
                            : null}
                          {error ? (
                            <FormHelperText error={true}>{error.data?.detail || 'Произошла ошибка!'}</FormHelperText>
                          ) : null}
                        </FormControl>
                        <Button
                          fullWidth
                          size={'large'}
                          type={'submit'}
                          variant={'contained'}
                          color={'secondary'}
                          disabled={!dirty || !isEmpty(errors)}
                        >
                          Сохранить
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={false} lg={true}></Grid>
      </Grid>
    </Box>
  );
};

export default PasswordReset;
