// ** React
import { useReducer, useContext, createContext } from 'react';

// ** Utils
import * as ordersClient from 'src/@core/utils/orders-client';

const OrdersStateContext = createContext();
const OrdersDispatchContext = createContext();

export const ordersTypes = {
  SET_ORDERS_PENDING: 'orders/SET_ORDERS_PENDING',
  SET_ORDERS_ERROR: 'orders/SET_ORDERS_ERROR',
  SET_ORDERS: 'orders/SET_ORDERS',
  SET_STATUSES_PENDING: 'orders/SET_STATUSES_PENDING',
  SET_STATUSES_ERROR: 'orders/SET_STATUSES_ERROR',
  SET_STATUSES: 'orders/SET_STATUSES',
};

const ordersDefaults = {
  orders: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  statuses: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
};

const ordersReducer = (orders, action) => {
  switch (action.type) {
    case ordersTypes.SET_ORDERS_PENDING: {
      return {
        ...orders,
        orders: {
          ...orders.orders,
          pending: action.payload,
        },
      };
    }
    case ordersTypes.SET_ORDERS_ERROR: {
      return {
        ...orders,
        orders: {
          ...orders.orders,
          error: action.payload,
        },
      };
    }
    case ordersTypes.SET_ORDERS: {
      return {
        ...orders,
        orders: {
          ...orders.orders,
          results: action.payload.results,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
      };
    }
    case ordersTypes.SET_STATUSES_PENDING: {
      return {
        ...orders,
        statuses: {
          ...orders.statuses,
          pending: action.payload,
        },
      };
    }
    case ordersTypes.SET_STATUSES_ERROR: {
      return {
        ...orders,
        statuses: {
          ...orders.statuses,
          error: action.payload,
        },
      };
    }
    case ordersTypes.SET_STATUSES: {
      return {
        ...orders,
        statuses: {
          ...orders.statuses,
          results: action.payload,
        },
      };
    }
    default: {
      return orders;
    }
  }
};

const OrdersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ordersReducer, ordersDefaults);

  const getOrders = async (params, token) => {
    dispatch({
      type: ordersTypes.SET_ORDERS_PENDING,
      payload: true,
    });
    try {
      const { data } = await ordersClient.getOrders(params, token);
      dispatch({
        type: ordersTypes.SET_ORDERS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: ordersTypes.SET_ORDERS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: ordersTypes.SET_ORDERS_PENDING,
        payload: false,
      });
    }
  };

  const getStatuses = async (params) => {
    dispatch({
      type: ordersTypes.SET_STATUSES_PENDING,
      payload: true,
    });
    try {
      const { data } = await ordersClient.getStatuses(params);
      dispatch({
        type: ordersTypes.SET_STATUSES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: ordersTypes.SET_STATUSES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: ordersTypes.SET_STATUSES_PENDING,
        payload: false,
      });
    }
  };

  const setStayInfo = async (id, values) => {
    const response = await ordersClient.setStayInfo(id, values);
    return response;
  };

  return (
    <OrdersStateContext.Provider value={state}>
      <OrdersDispatchContext.Provider value={{ dispatch, getOrders, getStatuses, setStayInfo }}>
        {children}
      </OrdersDispatchContext.Provider>
    </OrdersStateContext.Provider>
  );
};

const useOrdersDispatch = () => {
  const context = useContext(OrdersDispatchContext);

  if (context === undefined) {
    throw new Error(`useOrdersDispatch must be used within a OrdersProvider`);
  }
  return context;
};

const useOrdersState = () => {
  const context = useContext(OrdersStateContext);

  if (context === undefined) {
    throw new Error(`useOrdersState must be used within a OrdersProvider`);
  }
  return context;
};

export { OrdersProvider, useOrdersDispatch, useOrdersState };
