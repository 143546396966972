const Baseline = () => {
  return {
    MuiCssBaselinen: {
      styleOverrides: {
        body: {
          backgroundColor: '#EBEBEB',
          fontSize: 14,
          lineHeight: 1.2,
          fontFamily: ['"Ubuntu"', 'sans-serif'].join(','),
        },
      },
    },
  };
};

export default Baseline;
