// ** React
import { useReducer, useContext, createContext } from 'react';

// ** Utils
import * as userClient from 'src/@core/utils/user-client';

const UserStateContext = createContext();
const UserDispatchContext = createContext();

export const userTypes = {
  SET_MENU_PENDING: 'user/SET_MENU_PENDING',
  SET_MENU_ERROR: 'user/SET_MENU_ERROR',
  SET_MENU: 'user/SET_MENU',
};

const userDefaults = {
  menu: [],
  pendingMenu: false,
  errorMenu: {
    status: null,
    message: null,
  },
};

const userReducer = (user, action) => {
  switch (action.type) {
    case userTypes.SET_MENU_PENDING: {
      return {
        ...user,
        pendingMenu: action.payload,
      };
    }
    case userTypes.SET_MENU_ERROR: {
      return {
        ...user,
        errorMenu: action.payload,
      };
    }
    case userTypes.SET_MENU: {
      return {
        ...user,
        menu: action.payload,
      };
    }
    default: {
      return user;
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, userDefaults);

  const getMenu = async () => {
    dispatch({
      type: userTypes.SET_MENU_PENDING,
      payload: true,
    });
    try {
      const { data } = await userClient.getMenu();
      dispatch({
        type: userTypes.SET_MENU,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: userTypes.SET_MENU_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: userTypes.SET_MENU_PENDING,
        payload: false,
      });
    }
  };

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={{ dispatch, getMenu }}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error(`useUserDispatch must be used within a UserProvider`);
  }
  return context;
};

const useUserState = () => {
  const context = useContext(UserStateContext);

  if (context === undefined) {
    throw new Error(`useUserState must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUserDispatch, useUserState };
