// ** Constants
import { APP_API_URL, POST_REFRESH } from 'src/constants';

// ** Axios
import axios from 'axios';

// ** Helpers
import eventBus from 'src/@core/helpers/event-bus';
import { setData, getData } from 'src/@core/helpers/storage';

const http = axios.create({
  baseURL: APP_API_URL,
});

const doRefreshToken = async (token) => {
  const { data } = await http.post(POST_REFRESH, {
    refresh: token,
  });

  if (data.access) {
    setData('access', data.access);
  }

  return true;
};

http.interceptors.request.use(
  async (config) => {
    config.headers['Accept-Language'] = 'ru';

    const token = getData('access');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    const activeHotel = getData('activeHotel');

    if (activeHotel) {
      config.headers['managed-hotel'] = activeHotel;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (!originalRequest.__retry) {
        originalRequest.__retry = true;
        const refreshToken = getData('refresh');

        if (refreshToken) {
          try {
            await doRefreshToken(refreshToken);
            return http.request(originalRequest);
          } catch (e) {
            eventBus.publish('auth:logout');
            return Promise.reject(error);
          }
        } else {
          eventBus.publish('auth:logout');
        }
      }
    }

    return Promise.reject(error);
  },
);

const http2 = axios.create({
  baseURL: APP_API_URL,
});

http2.interceptors.request.use(
  async (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers.accept = 'application/json';
    config.headers['Accept-Language'] = 'ru';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { http, http2 };

export const cancelToken = axios.CancelToken;
