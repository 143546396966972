// ** React
import { useReducer, useContext, createContext } from 'react';

// ** Lodash
import isEmpty from 'lodash/isEmpty';

// ** Helpers
import * as helpersClient from 'src/@core/utils/helpers-client';

const HelpersStateContext = createContext();
const HelpersDispatchContext = createContext();

export const helpersTypes = {
  SET_SERVICE_ORDER_STATUSES_PENDING: 'helpers/SET_SERVICE_ORDER_STATUSES_PENDING',
  SET_SERVICE_ORDER_STATUSES_ERROR: 'helpers/SET_SERVICE_ORDER_STATUSES_ERROR',
  SET_SERVICE_ORDER_STATUSES: 'helpers/SET_SERVICE_ORDER_STATUSES',
  SET_LANGUAGES_PENDING: 'helpers/SET_LANGUAGES_PENDING',
  SET_LANGUAGES_ERROR: 'helpers/SET_LANGUAGES_ERROR',
  SET_LANGUAGES: 'helpers/SET_LANGUAGES',
  SET_CURRENT_LANGUAGE: 'helpers/SET_CURRENT_LANGUAGE',
  SET_HOTEL_CURRENCIES_PENDING: 'helpers/SET_HOTEL_CURRENCIES_PENDING',
  SET_HOTEL_CURRENCIES_ERROR: 'helpers/SET_HOTEL_CURRENCIES_ERROR',
  SET_HOTEL_CURRENCIES: 'helpers/SET_HOTEL_CURRENCIES',
  SET_HOTEL_FIELD_RESTRICTIONS_PENDING: 'helpers/SET_HOTEL_FIELD_RESTRICTIONS_PENDING',
  SET_HOTEL_FIELD_RESTRICTIONS_ERROR: 'helpers/SET_HOTEL_FIELD_RESTRICTIONS_ERROR',
  SET_HOTEL_FIELD_RESTRICTIONS: 'helpers/SET_HOTEL_FIELD_RESTRICTIONS',
  SET_ITEM_TYPES_PENDING: 'helpers/SET_ITEM_TYPES_PENDING',
  SET_ITEM_TYPES_ERROR: 'helpers/SET_ITEM_TYPES_ERROR',
  SET_ITEM_TYPES: 'helpers/SET_ITEM_TYPES',
  SET_APPROVE_TYPES_PENDING: 'helpers/SET_APPROVE_TYPES_PENDING',
  SET_APPROVE_TYPES_ERROR: 'helpers/SET_APPROVE_TYPES_ERROR',
  SET_APPROVE_TYPES: 'helpers/SET_APPROVE_TYPES',
  SET_BOOKING_TYPES_PENDING: 'helpers/SET_BOOKING_TYPES_PENDING',
  SET_BOOKING_TYPES_ERROR: 'helpers/SET_BOOKING_TYPES_ERROR',
  SET_BOOKING_TYPES: 'helpers/SET_BOOKING_TYPES',
  SET_GEO_LEVELS_PENDING: 'helpers/SET_GEO_LEVELS_PENDING',
  SET_GEO_LEVELS_ERROR: 'helpers/SET_GEO_LEVELS_ERROR',
  SET_GEO_LEVELS: 'helpers/SET_GEO_LEVELS',
  SET_FEEDBACK_VARIANTS_PENDING: 'helpers/SET_FEEDBACK_VARIANTS_PENDING',
  SET_FEEDBACK_VARIANTS_ERROR: 'helpers/SET_FEEDBACK_VARIANTS_ERROR',
  SET_FEEDBACK_VARIANTS: 'helpers/SET_FEEDBACK_VARIANTS',
  SET_HOTEL_CHANNELS_PENDING: 'helpers/SET_HOTEL_CHANNELS_PENDING',
  SET_HOTEL_CHANNELS_ERROR: 'helpers/SET_HOTEL_CHANNELS_ERROR',
  SET_HOTEL_CHANNELS: 'helpers/SET_HOTEL_CHANNELS',
  SET_HOTEL_FACILITIES_PENDING: 'helpers/SET_HOTEL_FACILITIES_PENDING',
  SET_HOTEL_FACILITIES_ERROR: 'helpers/SET_HOTEL_FACILITIES_ERROR',
  SET_HOTEL_FACILITIES: 'helpers/SET_HOTEL_FACILITIES',
  SET_MENUS_TYPES_PENDING: 'helpers/SET_MENUS_TYPES_PENDING',
  SET_MENUS_TYPES_ERROR: 'helpers/SET_MENUS_TYPES_ERROR',
  SET_MENUS_TYPES: 'helpers/SET_MENUS_TYPES',
  SET_ALL_FACILITIES_PENDING: 'helpers/SET_ALL_FACILITIES_PENDING',
  SET_ALL_FACILITIES_ERROR: 'helpers/SET_ALL_FACILITIES_ERROR',
  SET_ALL_FACILITIES: 'helpers/SET_ALL_FACILITIES',
  SET_ALL_CATEGORIES_PENDING: 'helpers/SET_ALL_CATEGORIES_PENDING',
  SET_ALL_CATEGORIES_ERROR: 'helpers/SET_ALL_CATEGORIES_ERROR',
  SET_ALL_CATEGORIES: 'helpers/SET_ALL_CATEGORIES',
};

const helpersDefaults = {
  serviceOrderStatuses: [],
  pendingServiceOrderStatuses: false,
  errorServiceOrderStatuses: {
    status: null,
    message: null,
  },
  languages: [],
  pendingLanguages: false,
  errorLanguages: {
    status: null,
    message: null,
  },
  currentLanguage: null,
  hotelCurrencies: [],
  pendingHotelCurrencies: false,
  errorHotelCurrencies: {
    status: null,
    message: null,
  },
  hotelFieldRestrictions: [],
  pendingHotelFieldRestrictions: false,
  errorHotelFieldRestrictions: {
    status: null,
    message: null,
  },
  photosTags: [],
  pendingPhotosTags: false,
  errorPhotosTags: {
    status: null,
    message: null,
  },
  itemTypes: [],
  pendingItemTypes: false,
  errorItemTypes: {
    status: null,
    message: null,
  },
  approveTypes: [],
  pendingApproveTypes: false,
  errorApproveTypes: {
    status: null,
    message: null,
  },
  bookingTypes: [],
  pendingBookingTypes: false,
  errorBookingTypes: {
    status: null,
    message: null,
  },
  geoLevels: [],
  pendingGeoLevels: false,
  errorGeoLevels: {
    status: null,
    message: null,
  },
  feedbackVariants: [],
  pendingFeedbackVariants: false,
  errorFeedbackVariants: {
    status: null,
    message: null,
  },
  hotelChannels: [],
  pendingHotelChannels: false,
  errorHotelChannels: {
    status: null,
    message: null,
  },
  hotelFacilities: [],
  pendingHotelFacilities: false,
  errorHotelFacilities: {
    status: null,
    message: null,
  },
  menusTypes: [],
  pendingMenusTypes: false,
  errorMenusTypes: {
    status: null,
    message: null,
  },
  allFacilities: [],
  pendingAllFacilities: false,
  errorAllFacilities: {
    status: null,
    message: null,
  },
  allCategories: [],
  pendingAllCategories: false,
  errorAllCategories: {
    status: null,
    message: null,
  },
};

const helpersReducer = (helpers, action) => {
  switch (action.type) {
    case helpersTypes.SET_SERVICE_ORDER_STATUSES_PENDING: {
      return {
        ...helpers,
        pendingServiceOrderStatuses: action.payload,
      };
    }
    case helpersTypes.SET_SERVICE_ORDER_STATUSES_ERROR: {
      return {
        ...helpers,
        errorServiceOrderStatuses: action.payload,
      };
    }
    case helpersTypes.SET_SERVICE_ORDER_STATUSES: {
      return {
        ...helpers,
        serviceOrderStatuses: action.payload,
      };
    }
    case helpersTypes.SET_LANGUAGES_PENDING: {
      return {
        ...helpers,
        pendingLanguages: action.payload,
      };
    }
    case helpersTypes.SET_LANGUAGES_ERROR: {
      return {
        ...helpers,
        errorLanguages: action.payload,
      };
    }
    case helpersTypes.SET_LANGUAGES: {
      return {
        ...helpers,
        languages: action.payload,
      };
    }
    case helpersTypes.SET_CURRENT_LANGUAGE: {
      return {
        ...helpers,
        currentLanguage: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_CURRENCIES_PENDING: {
      return {
        ...helpers,
        pendingHotelCurrencies: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_CURRENCIES_ERROR: {
      return {
        ...helpers,
        errorHotelCurrencies: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_CURRENCIES: {
      return {
        ...helpers,
        hotelCurrencies: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS_PENDING: {
      return {
        ...helpers,
        pendingHotelFieldRestrictions: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS_ERROR: {
      return {
        ...helpers,
        errorHotelFieldRestrictions: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS: {
      return {
        ...helpers,
        hotelFieldRestrictions: action.payload,
      };
    }
    case helpersTypes.SET_ITEM_TYPES_PENDING: {
      return {
        ...helpers,
        pendingItemTypes: action.payload,
      };
    }
    case helpersTypes.SET_ITEM_TYPES_ERROR: {
      return {
        ...helpers,
        errorItemTypes: action.payload,
      };
    }
    case helpersTypes.SET_ITEM_TYPES: {
      return {
        ...helpers,
        itemTypes: action.payload,
      };
    }
    case helpersTypes.SET_APPROVE_TYPES_PENDING: {
      return {
        ...helpers,
        pendingApproveTypes: action.payload,
      };
    }
    case helpersTypes.SET_APPROVE_TYPES_ERROR: {
      return {
        ...helpers,
        errorApproveTypes: action.payload,
      };
    }
    case helpersTypes.SET_APPROVE_TYPES: {
      return {
        ...helpers,
        approveTypes: action.payload,
      };
    }
    case helpersTypes.SET_BOOKING_TYPES_PENDING: {
      return {
        ...helpers,
        pendingBookingTypes: action.payload,
      };
    }
    case helpersTypes.SET_BOOKING_TYPES_ERROR: {
      return {
        ...helpers,
        errorBookingTypes: action.payload,
      };
    }
    case helpersTypes.SET_BOOKING_TYPES: {
      return {
        ...helpers,
        bookingTypes: action.payload,
      };
    }
    case helpersTypes.SET_GEO_LEVELS_PENDING: {
      return {
        ...helpers,
        pendingGeoLevels: action.payload,
      };
    }
    case helpersTypes.SET_GEO_LEVELS_ERROR: {
      return {
        ...helpers,
        errorGeoLevels: action.payload,
      };
    }
    case helpersTypes.SET_GEO_LEVELS: {
      return {
        ...helpers,
        geoLevels: action.payload,
      };
    }
    case helpersTypes.SET_FEEDBACK_VARIANTS_PENDING: {
      return {
        ...helpers,
        pendingFeedbackVariants: action.payload,
      };
    }
    case helpersTypes.SET_FEEDBACK_VARIANTS_ERROR: {
      return {
        ...helpers,
        errorFeedbackVariants: action.payload,
      };
    }
    case helpersTypes.SET_FEEDBACK_VARIANTS: {
      return {
        ...helpers,
        feedbackVariants: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FACILITIES_PENDING: {
      return {
        ...helpers,
        pendingHotelFacilities: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FACILITIES_ERROR: {
      return {
        ...helpers,
        errorHotelFacilities: action.payload,
      };
    }
    case helpersTypes.SET_HOTEL_FACILITIES: {
      return {
        ...helpers,
        hotelFacilities: action.payload,
      };
    }
    case helpersTypes.SET_MENUS_TYPES_PENDING: {
      return {
        ...helpers,
        pendingMenusTypes: action.payload,
      };
    }
    case helpersTypes.SET_MENUS_TYPES_ERROR: {
      return {
        ...helpers,
        errorMenusTypes: action.payload,
      };
    }
    case helpersTypes.SET_MENUS_TYPES: {
      return {
        ...helpers,
        menusTypes: action.payload,
      };
    }
    case helpersTypes.SET_ALL_FACILITIES_PENDING: {
      return {
        ...helpers,
        pendingAllFacilities: action.payload,
      };
    }
    case helpersTypes.SET_ALL_FACILITIES_ERROR: {
      return {
        ...helpers,
        errorAllFacilities: action.payload,
      };
    }
    case helpersTypes.SET_ALL_FACILITIES: {
      return {
        ...helpers,
        allFacilities: action.payload,
      };
    }
    case helpersTypes.SET_ALL_CATEGORIES_PENDING: {
      return {
        ...helpers,
        pendingAllCategories: action.payload,
      };
    }
    case helpersTypes.SET_ALL_CATEGORIES_ERROR: {
      return {
        ...helpers,
        errorAllCategories: action.payload,
      };
    }
    case helpersTypes.SET_ALL_CATEGORIES: {
      return {
        ...helpers,
        allCategories: action.payload,
      };
    }
    default: {
      return helpers;
    }
  }
};

const HelpersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(helpersReducer, helpersDefaults);

  const getServiceOrderStatuses = async () => {
    if (!isEmpty(state.serviceOrderStatuses)) return;

    dispatch({
      type: helpersTypes.SET_SERVICE_ORDER_STATUSES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getServiceOrderStatuses();
      dispatch({
        type: helpersTypes.SET_SERVICE_ORDER_STATUSES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_SERVICE_ORDER_STATUSES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_SERVICE_ORDER_STATUSES_PENDING,
        payload: false,
      });
    }
  };

  const getLanguages = async () => {
    if (!isEmpty(state.languages)) return;

    dispatch({
      type: helpersTypes.SET_LANGUAGES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getLanguages();
      dispatch({
        type: helpersTypes.SET_LANGUAGES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_LANGUAGES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_LANGUAGES_PENDING,
        payload: false,
      });
    }
  };

  const setCurrentLanguage = (value) => {
    if (!value) return;

    dispatch({
      type: helpersTypes.SET_CURRENT_LANGUAGE,
      payload: value,
    });
  };

  const translateMessage = async ({ id, language }) => {
    try {
      const response = await helpersClient.translateMessage({
        id: id,
        language: language,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  };

  const getHotelCurrencies = async () => {
    if (!isEmpty(state.hotelCurrencies)) return;

    dispatch({
      type: helpersTypes.SET_HOTEL_CURRENCIES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getHotelCurrencies();
      dispatch({
        type: helpersTypes.SET_HOTEL_CURRENCIES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_HOTEL_CURRENCIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_HOTEL_CURRENCIES_PENDING,
        payload: false,
      });
    }
  };

  const getHotelFieldRestrictions = async () => {
    if (!isEmpty(state.hotelFieldRestrictions)) return;

    dispatch({
      type: helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getHotelFieldRestrictions();
      dispatch({
        type: helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_HOTEL_FIELD_RESTRICTIONS_PENDING,
        payload: false,
      });
    }
  };

  const getItemTypes = async () => {
    if (!isEmpty(state.itemTypes)) return;

    dispatch({
      type: helpersTypes.SET_ITEM_TYPES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getItemTypes();
      dispatch({
        type: helpersTypes.SET_ITEM_TYPES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_ITEM_TYPES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_ITEM_TYPES_PENDING,
        payload: false,
      });
    }
  };

  const getApproveTypes = async () => {
    if (!isEmpty(state.approveTypes)) return;

    dispatch({
      type: helpersTypes.SET_APPROVE_TYPES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getApproveTypes();
      dispatch({
        type: helpersTypes.SET_APPROVE_TYPES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_APPROVE_TYPES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_APPROVE_TYPES_PENDING,
        payload: false,
      });
    }
  };

  const getBookingTypes = async () => {
    if (!isEmpty(state.bookingTypes)) return;

    dispatch({
      type: helpersTypes.SET_BOOKING_TYPES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getBookingTypes();
      dispatch({
        type: helpersTypes.SET_BOOKING_TYPES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_BOOKING_TYPES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_BOOKING_TYPES_PENDING,
        payload: false,
      });
    }
  };

  const getGeoLevels = async () => {
    if (!isEmpty(state.geoLevels)) return;

    dispatch({
      type: helpersTypes.SET_GEO_LEVELS_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getGeoLevels();
      dispatch({
        type: helpersTypes.SET_GEO_LEVELS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_GEO_LEVELS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_GEO_LEVELS_PENDING,
        payload: false,
      });
    }
  };

  const getFeedbackVariants = async () => {
    if (!isEmpty(state.feedbackVariants)) return;

    dispatch({
      type: helpersTypes.SET_FEEDBACK_VARIANTS_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getFeedbackVariants();
      dispatch({
        type: helpersTypes.SET_FEEDBACK_VARIANTS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_FEEDBACK_VARIANTS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_FEEDBACK_VARIANTS_PENDING,
        payload: false,
      });
    }
  };

  const getHotelFacilities = async () => {
    if (!isEmpty(state.hotelFacilities)) return;

    dispatch({
      type: helpersTypes.SET_HOTEL_FACILITIES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getHotelFacilities();
      dispatch({
        type: helpersTypes.SET_HOTEL_FACILITIES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_HOTEL_FACILITIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_HOTEL_FACILITIES_PENDING,
        payload: false,
      });
    }
  };

  const getMenusTypes = async () => {
    if (!isEmpty(state.menusTypes)) return;

    dispatch({
      type: helpersTypes.SET_MENUS_TYPES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getMenusTypes();
      dispatch({
        type: helpersTypes.SET_MENUS_TYPES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_MENUS_TYPES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_MENUS_TYPES_PENDING,
        payload: false,
      });
    }
  };

  const getAllFacilities = async () => {
    if (!isEmpty(state.allFacilities)) return;

    dispatch({
      type: helpersTypes.SET_ALL_FACILITIES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getAllFacilities();
      dispatch({
        type: helpersTypes.SET_ALL_FACILITIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_ALL_FACILITIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_ALL_FACILITIES_PENDING,
        payload: false,
      });
    }
  };

  const getAllCategories = async () => {
    if (!isEmpty(state.allCategories)) return;

    dispatch({
      type: helpersTypes.SET_ALL_CATEGORIES_PENDING,
      payload: true,
    });
    try {
      const { data } = await helpersClient.getAllCategories();
      dispatch({
        type: helpersTypes.SET_ALL_CATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: helpersTypes.SET_ALL_CATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: helpersTypes.SET_ALL_CATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  return (
    <HelpersStateContext.Provider value={state}>
      <HelpersDispatchContext.Provider
        value={{
          dispatch,
          getServiceOrderStatuses,
          getLanguages,
          setCurrentLanguage,
          translateMessage,
          getHotelCurrencies,
          getHotelFieldRestrictions,
          getItemTypes,
          getApproveTypes,
          getBookingTypes,
          getGeoLevels,
          getFeedbackVariants,
          getHotelFacilities,
          getMenusTypes,
          getAllFacilities,
          getAllCategories,
        }}
      >
        {children}
      </HelpersDispatchContext.Provider>
    </HelpersStateContext.Provider>
  );
};

const useHelpersDispatch = () => {
  const context = useContext(HelpersDispatchContext);

  if (context === undefined) {
    throw new Error(`useHelpersDispatch must be used within a HelpersProvider`);
  }
  return context;
};

const useHelpersState = () => {
  const context = useContext(HelpersStateContext);

  if (context === undefined) {
    throw new Error(`useHelpersState must be used within a HelpersProvider`);
  }
  return context;
};

export { HelpersProvider, useHelpersDispatch, useHelpersState };
