// ** Constants
import {
  GET_STAY,
  GET_SERVICE_ORDERS,
  GET_SERVICE_ORDERS_STATUSES,
  GET_ORDER,
  GET_ORDER_TAKE,
  GET_ORDER_CANCEL,
  GET_ORDER_COMPLETE,
  GET_ORDER_HISTORY,
  POST_STAY_SET_ROOM,
  POST_ORDER,
} from 'src/constants';

// ** Helpers
import { http } from 'src/@core/helpers/http';

const getStay = async (id) => {
  const response = await http.get(GET_STAY.replace(':id', id));

  return response;
};

const getOrders = async (params, token) => {
  const response = await http.get(GET_SERVICE_ORDERS, {
    params: {
      ...params,
      page_size: 999,
    },
    cancelToken: token,
  });

  return response;
};

const getOrder = async (id) => {
  const response = await http.get(GET_ORDER.replace(':id', id));

  return response;
};

const getOrderHistory = async (id) => {
  const response = await http.get(GET_ORDER_HISTORY.replace(':id', id));

  return response;
};

const takeOrder = async (id) => {
  const response = await http.get(GET_ORDER_TAKE.replace(':id', id));

  return response;
};

const cancelOrder = async (id) => {
  const response = await http.get(GET_ORDER_CANCEL.replace(':id', id));

  return response;
};

const completeOrder = async (id) => {
  const response = await http.get(GET_ORDER_COMPLETE.replace(':id', id));

  return response;
};

const getStatuses = async (params) => {
  const response = await http.get(GET_SERVICE_ORDERS_STATUSES, {
    params: params,
  });

  return response;
};

const setStayInfo = async (id, values) => {
  const response = await http.post(POST_STAY_SET_ROOM.replace(':id', id), values);

  return response;
};

const submitOrder = async ({ data }) => {
  const response = await http.post(POST_ORDER, data);

  return response;
};

export {
  getStay,
  getOrders,
  getOrder,
  getOrderHistory,
  getStatuses,
  takeOrder,
  cancelOrder,
  completeOrder,
  setStayInfo,
  submitOrder,
};
