const Button = () => {
  return {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none !important',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: '#4BA8B3',
          color: '#fff',
          '&.Mui-disabled': {
            backgroundColor: '#4BA8B3',
            color: '#fff',
            opacity: 0.4,
          },
          '&:hover': {
            backgroundColor: '#4BA8B3',
          },
        },
        containedSecondary: {
          boxShadow: 'none',
          backgroundColor: '#DC4E5C',
          color: '#fff',
          '&.Mui-disabled': {
            backgroundColor: '#DC4E5C',
            color: '#fff',
            opacity: 0.4,
          },
          '&:hover': {
            backgroundColor: '#DC4E5C',
          },
        },
        containedSizeLarge: {
          paddingTop: 18,
          paddingBottom: 18,
          paddingLeft: 15,
          paddingRight: 15,
          fontSize: 14,
          lineHeight: '20px',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'transparent',
        },
        outlinedPrimary: {
          color: '#3D3D3D',
          borderColor: '#CCCCCC',
          '&:hover': {
            borderColor: '#CCCCCC',
          },
        },
        outlinedSizeLarge: {
          paddingTop: 18,
          paddingBottom: 18,
          paddingLeft: 15,
          paddingRight: 15,
          fontSize: 14,
          lineHeight: '20px',
        },
      },
    },
  };
};

export default Button;
