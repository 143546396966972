// ** React
import { useEffect, lazy, Suspense } from 'react';

// ** React Router DOM
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// ** MUI
import Box from '@mui/material/Box';

// ** Helpers
import eventBus from 'src/@core/helpers/event-bus';

// ** Context
import { useAuth } from 'src/@core/context/auth-context';

// ** Components
import Spinner from 'src/@core/components/Spinner';

// ** Pages
import PasswordReset from 'src/pages/PasswordReset';
import Activate from 'src/pages/Activate';

// ** Libs
import DatePickerWrapper from 'src/@core/styles/libs/reactDatePicker';

const loadAuthenticatedApp = () => import('src/AuthenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('src/UnauthenticatedApp'));

const App = () => {
  const { data, logout } = useAuth();

  useEffect(() => {
    eventBus.subscribe('auth:logout', () => {
      logout();
    });
  }, []);

  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Routes>
          <Route path={'password-reset/:uid/:token'} strict element={<PasswordReset />} />
          <Route path={'activate/:uid/:token'} strict element={<Activate />} />
          {data.user ? (
            <Route path={'*'} element={<AuthenticatedApp />} />
          ) : (
            <Route path={'*'} element={<UnauthenticatedApp />} />
          )}
        </Routes>
      </Router>

      <DatePickerWrapper sx={{ zIndex: 11 }}>
        <Box id={'react-datepicker-portal'}></Box>
      </DatePickerWrapper>
    </Suspense>
  );
};

export default App;
