// ** React
import { useContext, useEffect, createContext, useState } from 'react';

// ** Utils
import * as authClient from 'src/@core/utils/auth-client';

// ** Helpers
import { processAppData } from 'src/@core/helpers/app-data';

// ** Components
import Spinner from 'src/@core/components/Spinner';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState({
    user: null,
    activeHotel: null,
    isManagedHotel: false,
    isAdminedHotel: false,
  });
  const [isPending, setIsPending] = useState(false);

  const checkData = async () => {
    const response = await processAppData();
    setData(response);

    return response;
  };

  useEffect(() => {
    if (window.location.pathname.includes('/activate/') || window.location.pathname.includes('/password-reset/'))
      return;

    const load = async () => {
      setIsPending(true);
      await checkData();
      setIsPending(false);
    };
    load();
  }, []);

  const login = async (form) => {
    const response = await authClient.login(form);
    await checkData();

    return response;
  };

  const logout = async () => {
    await authClient.logout();
    checkData();
  };

  const activate = async ({ uid, token }) => {
    const response = await authClient.activate({ uid, token });

    return response;
  };

  const resetPasswordConfirm = async ({ uid, token, newPassword }) => {
    const response = await authClient.resetPasswordConfirm({ uid, token, newPassword });

    return response;
  };

  const updateUser = async (values) => {
    const response = await authClient.updateUser(values);
    await checkData();

    return response;
  };

  const setPassword = async ({ newPassword, currentPassword }) => {
    const response = await authClient.setPassword({
      newPassword: newPassword,
      currentPassword: currentPassword,
    });

    return response;
  };

  if (isPending) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ data, login, logout, activate, resetPasswordConfirm, updateUser, setPassword }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
