// ** Constants
import {
  GET_SERVICE_ORDERS_STATUSES_LIST,
  GET_LANGUAGES_LIST,
  POST_TRANSLATE_MESSAGE,
  GET_HOTEL_CURRENCIES,
  GET_HOTEL_FIELD_RESTRICTIONS,
  GET_ITEM_TYPES,
  GET_APPROVE_TYPES,
  GET_BOOKING_TYPES,
  GET_GEO_LEVELS,
  GET_FEEDBACK_VARIANTS,
  GET_FACILITIES,
  GET_MENUS_TYPES,
  GET_ALL_FACILITIES,
  GET_ALL_CATEGORIES,
} from 'src/constants';

// ** Helpers
import { http } from 'src/@core/helpers/http';

const getServiceOrderStatuses = async () => {
  const response = await http.get(GET_SERVICE_ORDERS_STATUSES_LIST);

  return response;
};

const getLanguages = async () => {
  const response = await http.get(GET_LANGUAGES_LIST);

  return response;
};

const translateMessage = async ({ id, language }) => {
  const response = await http.post(POST_TRANSLATE_MESSAGE.replace(':id', id), {
    destination_language: language,
  });

  return response;
};

const getHotelCurrencies = async () => {
  const response = await http.get(GET_HOTEL_CURRENCIES);

  return response;
};

const getHotelFieldRestrictions = async () => {
  const response = await http.get(GET_HOTEL_FIELD_RESTRICTIONS);

  return response;
};

const getItemTypes = async () => {
  const response = await http.get(GET_ITEM_TYPES);

  return response;
};

const getApproveTypes = async () => {
  const response = await http.get(GET_APPROVE_TYPES);

  return response;
};

const getBookingTypes = async () => {
  const response = await http.get(GET_BOOKING_TYPES);

  return response;
};

const getGeoLevels = async () => {
  const response = await http.get(GET_GEO_LEVELS);

  return response;
};

const getFeedbackVariants = async () => {
  const response = await http.get(GET_FEEDBACK_VARIANTS);

  return response;
};

const getHotelFacilities = async () => {
  const response = await http.get(GET_FACILITIES);

  return response;
};

const getMenusTypes = async () => {
  const response = await http.get(GET_MENUS_TYPES);

  return response;
};

const getAllFacilities = async () => {
  const response = await http.get(GET_ALL_FACILITIES, {
    params: {
      page: 1,
      page_size: 999,
    },
  });

  return response;
};

const getAllCategories = async () => {
  const response = await http.get(GET_ALL_CATEGORIES, {
    params: {
      page: 1,
      page_size: 999,
    },
  });

  return response;
};

export {
  getServiceOrderStatuses,
  getLanguages,
  translateMessage,
  getHotelCurrencies,
  getHotelFieldRestrictions,
  getItemTypes,
  getApproveTypes,
  getBookingTypes,
  getGeoLevels,
  getFeedbackVariants,
  getHotelFacilities,
  getMenusTypes,
  getAllFacilities,
  getAllCategories,
};
