const input = () => {
  return {
    MuiInput: {
      styleOverrides: {
        root: {
          color: '#212121',
          paddingTop: 8,
          paddingRight: 24,
          paddingBottom: 10,
          paddingLeft: 24,
          'label + &': {
            marginTop: 0,
            paddingTop: 26,
          },
        },
        input: {
          fontSize: 16,
          lineHeight: '20px',
          paddingTop: 2,
          paddingBottom: 0,
          height: '1.1876em',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 0,
          color: '#A6A6A6',
          fontSize: 16,
          lineHeight: '20px',
          '&.Mui-focused': {
            color: '#A6A6A6',
          },
          '&.Mui-error': {
            color: '#A6A6A6',
          },
        },
        formControl: {
          left: 24,
          transform: 'translate(0, 16px) scale(1)',
        },
        shrink: {
          paddingTop: 9,
          fontSize: 12,
          lineHeight: '16px',
          transform: 'translate(0, 1.5px) scale(0.75)',
        },
      },
    },
  };
};

export default input;
