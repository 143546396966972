// ** Constants
import {
  GET_HOTEL,
  PATCH_HOTEL,
  GET_PHOTOS,
  DELETE_PHOTO,
  POST_PHOTO_SET_MAIN,
  POST_PHOTOS,
  GET_CATEGORIES,
  GET_FILLED_CATEGORIES,
  GET_FACILITIES,
  POST_FACILITY_SELECT,
  POST_FACILITY_UNSELECT,
  POST_FACILITY_LINK,
  POST_FACILITY_UNLINK,
  GET_SERVICES,
  POST_SERVICE,
  GET_SERVICE,
  PUT_SERVICE,
  DELETE_SERVICE,
  GET_CHANNELS_TREE_FOR_HOTELS,
  GET_POSITIONS_TREE_FOR_HOTELS,
  POST_CHANNELS_ADD_CHILD_NODE,
  POST_POSITIONS_ADD_CHILD_NODE,
  GET_POSITION_CHANNELS,
  POST_CHANNELS_RENAME_NODE,
  POST_POSITIONS_RENAME_NODE,
  DELETE_POSITION,
  DELETE_CHANNEL,
  GET_CHANNEL_SUBSCRIBED_POSITIONS,
  GET_CHANNEL_SUBSCRIBED_USERS,
  GET_ACCOUNTS_STAFF,
  POST_ACCOUNTS_ADD_STAFF,
  PATCH_ACCOUNTS_EDIT_STAFF,
  POST_ACCOUNTS_DEACTIVATE_STAFF,
  POST_SUBSCRIBE_TO_CHANNEL,
  POST_UNSUBSCRIBE_FROM_CHANNEL,
  POST_ADD_POSITION_TO_USER,
  POST_REMOVE_POSITION_FROM_USER,
  GET_BILLING_GATEWAYS,
  GET_BILLING_POINTS,
  POST_BILLING_POINT,
  GET_PROMO_LANDINGS,
  PATCH_PROMO_LANDING,
  POST_PROMO_LANDING,
  DELETE_PROMO_LANDING,
  GET_VCATEGORIES,
  POST_VCATEGORY,
  PATCH_VCATEGORY,
  DELETE_VCATEGORY,
  GET_VCATEGORY_ITEMS,
  POST_VCATEGORY_ITEM,
  PATCH_VCATEGORY_ITEM,
  DELETE_VCATEGORY_ITEM,
  POST_VCATEGORY_ITEMS_SORT,
  GET_MENUS,
  POST_MENU,
  PATCH_MENU,
  DELETE_MENU,
  GET_MENU_ITEMS,
  POST_MENU_ITEM,
  PATCH_MENU_ITEM,
  DELETE_MENU_ITEM,
  POST_MENU_ITEMS_SORT,
  GET_SAFETY_CATEGORIES,
  GET_SIGHTS_CATEGORIES,
  GET_SAFETY_ITEMS,
  POST_SAFETY_ITEM,
  PATCH_SAFETY_ITEM,
  DELETE_SAFETY_ITEM,
  GET_SIGHTS_ITEMS,
  POST_SIGHTS_ITEM,
  PATCH_SIGHTS_ITEM,
  DELETE_SIGHTS_ITEM,
} from 'src/constants';

// ** Helpers
import { http } from 'src/@core/helpers/http';

const getHotel = async (id) => {
  const response = await http.get(GET_HOTEL.replace(':id', id));

  return response;
};

const updateHotel = async (id, values) => {
  const response = await http.patch(PATCH_HOTEL.replace(':id', id), values);

  return response;
};

const getPhotos = async (type = 'hotel', id, page = 1) => {
  const response = await http.get(GET_PHOTOS.replace(':id', id).replace(':type', type), {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const deletePhoto = async (id) => {
  const response = await http.delete(DELETE_PHOTO.replace(':id', id));

  return response;
};

const setMainPhoto = async (id) => {
  const response = await http.post(POST_PHOTO_SET_MAIN.replace(':id', id));

  return response;
};

const uploadPhoto = async (type = 'hotel', id, image) => {
  const fd = new FormData();
  fd.append('contentType', type);
  fd.append('objectID', id);
  fd.append('file', image);

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  const response = await http.post(POST_PHOTOS, fd, config);

  return response;
};

const getCategories = async (page = 1) => {
  const response = await http.get(GET_CATEGORIES, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const getFilledCategories = async (page = 1) => {
  const response = await http.get(GET_FILLED_CATEGORIES, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const getFacilities = async ({ category = null }) => {
  const response = await http.get(GET_FACILITIES, {
    params: {
      category: category === null ? 'null' : category === 'all' ? '' : category,
    },
  });

  return response;
};

const selectFacility = async (id) => {
  const response = await http.post(POST_FACILITY_SELECT.replace(':id', id));

  return response;
};

const unselectFacility = async (id) => {
  const response = await http.post(POST_FACILITY_UNSELECT.replace(':id', id));

  return response;
};

const linkFacility = async (id) => {
  const response = await http.post(POST_FACILITY_LINK.replace(':id', id));

  return response;
};

const unlinkFacility = async (id) => {
  const response = await http.post(POST_FACILITY_UNLINK.replace(':id', id));

  return response;
};

const getServices = async (page = 1, token) => {
  const response = await http.get(GET_SERVICES, {
    params: {
      page: page,
      page_size: 999,
    },
    cancelToken: token,
  });

  return response;
};

const addService = async (values) => {
  const response = await http.post(POST_SERVICE, values);

  return response;
};

const getService = async (id) => {
  const response = await http.get(GET_SERVICE.replace(':id', id));

  return response;
};

const editService = async (id, values) => {
  const response = await http.put(PUT_SERVICE.replace(':id', id), values);

  return response;
};

const deleteService = async (id) => {
  const response = await http.delete(DELETE_SERVICE.replace(':id', id));

  return response;
};

const getVcategories = async (page = 1) => {
  const response = await http.get(GET_VCATEGORIES, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const addVcategory = async (values) => {
  const response = await http.post(POST_VCATEGORY, values);

  return response;
};

const editVcategory = async (id, values) => {
  const response = await http.patch(PATCH_VCATEGORY.replace(':id', id), values);

  return response;
};

const deleteVcategory = async (id) => {
  const response = await http.delete(DELETE_VCATEGORY.replace(':id', id));

  return response;
};

const getVcategoryItems = async (vcategoryId, page = 1) => {
  const response = await http.get(GET_VCATEGORY_ITEMS, {
    params: {
      virtual_category: vcategoryId,
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const addVcategoryItem = async (values) => {
  const response = await http.post(POST_VCATEGORY_ITEM, values);

  return response;
};

const editVcategoryItem = async (id, values) => {
  const response = await http.patch(PATCH_VCATEGORY_ITEM.replace(':id', id), values);

  return response;
};

const deleteVcategoryItem = async (id) => {
  const response = await http.delete(DELETE_VCATEGORY_ITEM.replace(':id', id));

  return response;
};

const sortVcategoryItems = async (values) => {
  const response = await http.post(POST_VCATEGORY_ITEMS_SORT, values);

  return response;
};

const getChannelsTree = async () => {
  const response = await http.get(GET_CHANNELS_TREE_FOR_HOTELS);

  return response;
};

const getPositionsTree = async () => {
  const response = await http.get(GET_POSITIONS_TREE_FOR_HOTELS);

  return response;
};

const addChannelsChildNode = async (id, values) => {
  const response = await http.post(POST_CHANNELS_ADD_CHILD_NODE.replace(':id', id), values);

  return response;
};

const addPositionsChildNode = async (id, values) => {
  const response = await http.post(POST_POSITIONS_ADD_CHILD_NODE.replace(':id', id), values);

  return response;
};

const renameChannel = async (id, values) => {
  const response = await http.post(POST_CHANNELS_RENAME_NODE.replace(':id', id), values);

  return response;
};

const renamePosition = async (id, values) => {
  const response = await http.post(POST_POSITIONS_RENAME_NODE.replace(':id', id), values);

  return response;
};

const deleteChannel = async (id) => {
  const response = await http.delete(DELETE_CHANNEL.replace(':id', id));

  return response;
};

const deletePosition = async (id) => {
  const response = await http.delete(DELETE_POSITION.replace(':id', id));

  return response;
};

const getChannelSubscribedPositions = async (id) => {
  const response = await http.get(GET_CHANNEL_SUBSCRIBED_POSITIONS.replace(':id', id));

  return response;
};

const getChannelSubscribedUsers = async (id) => {
  const response = await http.get(GET_CHANNEL_SUBSCRIBED_USERS.replace(':id', id));

  return response;
};

const getPositionChannels = async (id) => {
  const response = await http.get(GET_POSITION_CHANNELS.replace(':id', id));

  return response;
};

const getAccountsStaff = async () => {
  const response = await http.get(GET_ACCOUNTS_STAFF);

  return response;
};

const addAccountsStaff = async (values) => {
  const response = await http.post(POST_ACCOUNTS_ADD_STAFF, values);

  return response;
};

const editAccountsStaff = async (id, values) => {
  const response = await http.patch(PATCH_ACCOUNTS_EDIT_STAFF.replace(':id', id), values);

  return response;
};

const deactivateAccountsStaff = async (id, values) => {
  const response = await http.post(POST_ACCOUNTS_DEACTIVATE_STAFF.replace(':id', id), values);

  return response;
};

const subscribeUserToChannel = async (id, userId) => {
  const response = await http.post(POST_SUBSCRIBE_TO_CHANNEL.replace(':id', id), {
    userId: userId,
  });

  return response;
};

const subscribePositionToChannel = async (id, positionId) => {
  const response = await http.post(POST_SUBSCRIBE_TO_CHANNEL.replace(':id', id), {
    positionId: positionId,
  });

  return response;
};

const unsubscribeUserFromChannel = async (id, userId) => {
  const response = await http.post(POST_UNSUBSCRIBE_FROM_CHANNEL.replace(':id', id), {
    userId: userId,
  });

  return response;
};

const unsubscribePositionFromChannel = async (id, positionId) => {
  const response = await http.post(POST_UNSUBSCRIBE_FROM_CHANNEL.replace(':id', id), {
    positionId: positionId,
  });

  return response;
};

const addPositionToUser = async (id, userId) => {
  const response = await http.post(POST_ADD_POSITION_TO_USER.replace(':id', id), {
    userId: userId,
  });

  return response;
};

const removePositionFromUser = async (id, userId) => {
  const response = await http.post(POST_REMOVE_POSITION_FROM_USER.replace(':id', id), {
    userId: userId,
  });

  return response;
};

const getBillingGateways = async () => {
  const response = await http.get(GET_BILLING_GATEWAYS);

  return response;
};

const getBillingPoints = async (page = 1) => {
  const response = await http.get(GET_BILLING_POINTS, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const addBillingPoint = async (values) => {
  const response = await http.post(POST_BILLING_POINT, values);

  return response;
};

const getPromoLandings = async (page = 1) => {
  const response = await http.get(GET_PROMO_LANDINGS, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const addPromoLanding = async (values) => {
  const response = await http.post(POST_PROMO_LANDING, values);

  return response;
};

const editPromoLanding = async (id, values) => {
  const response = await http.patch(PATCH_PROMO_LANDING.replace(':id', id), values);

  return response;
};

const deletePromoLanding = async (id) => {
  const response = await http.delete(DELETE_PROMO_LANDING.replace(':id', id));

  return response;
};

const getMenus = async (hotel, page = 1) => {
  const response = await http.get(GET_MENUS, {
    params: {
      page: page,
      hotel: hotel,
      page_size: 999,
    },
  });

  return response;
};

const addMenu = async (values) => {
  const response = await http.post(POST_MENU, values);

  return response;
};

const editMenu = async (id, values) => {
  const response = await http.patch(PATCH_MENU.replace(':id', id), values);

  return response;
};

const deleteMenu = async (id) => {
  const response = await http.delete(DELETE_MENU.replace(':id', id));

  return response;
};

const getMenuItems = async (menu, page = 1) => {
  const response = await http.get(GET_MENU_ITEMS, {
    params: {
      menu: menu,
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const addMenuItem = async (values) => {
  const response = await http.post(POST_MENU_ITEM, values);

  return response;
};

const editMenuItem = async (id, values) => {
  const response = await http.patch(PATCH_MENU_ITEM.replace(':id', id), values);

  return response;
};

const deleteMenuItem = async (id) => {
  const response = await http.delete(DELETE_MENU_ITEM.replace(':id', id));

  return response;
};

const sortMenuItems = async (values) => {
  const response = await http.post(POST_MENU_ITEMS_SORT, values);

  return response;
};

const getSafetyCategories = async (page = 1) => {
  const response = await http.get(GET_SAFETY_CATEGORIES, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const getSafetyItems = async ({ category = null }) => {
  const response = await http.get(GET_SAFETY_ITEMS, {
    params: {
      category: category,
    },
  });

  return response;
};

const addSafetyItem = async (values) => {
  const response = await http.post(POST_SAFETY_ITEM, values, { headers: { 'Content-Type': 'multipart/form-data' } });

  return response;
};

const editSafetyItem = async (id, values) => {
  const response = await http.patch(PATCH_SAFETY_ITEM.replace(':id', id), values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
};

const deleteSafetyItem = async (id) => {
  const response = await http.delete(DELETE_SAFETY_ITEM.replace(':id', id));

  return response;
};

const getSightsCategories = async (page = 1) => {
  const response = await http.get(GET_SIGHTS_CATEGORIES, {
    params: {
      page: page,
      page_size: 999,
    },
  });

  return response;
};

const getSightsItems = async ({ category = null }) => {
  const response = await http.get(GET_SIGHTS_ITEMS, {
    params: {
      category: category,
    },
  });

  return response;
};

const addSightsItem = async (values) => {
  const response = await http.post(POST_SIGHTS_ITEM, values, { headers: { 'Content-Type': 'multipart/form-data' } });

  return response;
};

const editSightsItem = async (id, values) => {
  const response = await http.patch(PATCH_SIGHTS_ITEM.replace(':id', id), values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
};

const deleteSightsItem = async (id) => {
  const response = await http.delete(DELETE_SIGHTS_ITEM.replace(':id', id));

  return response;
};

export {
  getHotel,
  updateHotel,
  getPhotos,
  deletePhoto,
  setMainPhoto,
  uploadPhoto,
  getCategories,
  getFilledCategories,
  getFacilities,
  selectFacility,
  unselectFacility,
  linkFacility,
  unlinkFacility,
  getServices,
  addService,
  getService,
  editService,
  deleteService,
  getVcategories,
  addVcategory,
  editVcategory,
  deleteVcategory,
  getVcategoryItems,
  addVcategoryItem,
  editVcategoryItem,
  deleteVcategoryItem,
  sortVcategoryItems,
  getChannelsTree,
  getPositionsTree,
  addChannelsChildNode,
  addPositionsChildNode,
  renameChannel,
  renamePosition,
  deleteChannel,
  deletePosition,
  getChannelSubscribedPositions,
  getChannelSubscribedUsers,
  getPositionChannels,
  getAccountsStaff,
  addAccountsStaff,
  editAccountsStaff,
  deactivateAccountsStaff,
  subscribeUserToChannel,
  subscribePositionToChannel,
  unsubscribeUserFromChannel,
  unsubscribePositionFromChannel,
  addPositionToUser,
  removePositionFromUser,
  getBillingGateways,
  getBillingPoints,
  addBillingPoint,
  getPromoLandings,
  addPromoLanding,
  editPromoLanding,
  deletePromoLanding,
  getMenus,
  addMenu,
  editMenu,
  deleteMenu,
  getMenuItems,
  addMenuItem,
  editMenuItem,
  deleteMenuItem,
  sortMenuItems,
  getSafetyCategories,
  getSafetyItems,
  addSafetyItem,
  editSafetyItem,
  deleteSafetyItem,
  getSightsCategories,
  getSightsItems,
  addSightsItem,
  editSightsItem,
  deleteSightsItem,
};
