const helperText = () => {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          '&.Mui-error': {
            color: '#FE6A6A',
            fontSize: 11,
            lineHeight: '16px',
            marginTop: 0,
          },
        },
      },
    },
  };
};

export default helperText;
