// ** React DOM
import { createRoot } from 'react-dom/client';

// ** App
import App from 'src/App';

// ** Report Witals
import reportWebVitals from 'src/reportWebVitals';

// ** Constants
import { ENVIRONMENT } from 'src/constants';

// ** Context
import { AppProviders } from 'src/@core/context';

// ** Sentry
import * as Sentry from '@sentry/react';

// ** Theme Setup
import ThemeComponent from 'src/@core/theme/ThemeComponent';

// ** Styles
import 'src/styles/globals.css';
import 'react-datepicker/dist/react-datepicker.css';

if (ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://4772efb8c6d6409ebadce0d106d6dde8@sentry2.m5test.ru/17',
    integrations: [Sentry.browserTracingIntegration()],
    environment: ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppProviders>
    <ThemeComponent>
      <App />
    </ThemeComponent>
  </AppProviders>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
