// ** Constants
import {
  POST_LOGIN,
  POST_ACTIVATION,
  GET_USERS_ME,
  PATCH_USERS_ME,
  POST_SET_PASSWORD,
  POST_RESET_PASSWORD_CONFIRM,
} from 'src/constants';

// ** Lodash
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/uniqBy';

// ** Helpers
import { http, http2 } from 'src/@core/helpers/http';
import { setData, getData, removeData } from 'src/@core/helpers/storage';

const handleUserResponse = ({ access, refresh, ...data }) => {
  setData('access', access);
  setData('refresh', refresh);

  return data;
};

const login = async ({ email, password }) => {
  const { data } = await http2.post(POST_LOGIN, {
    email,
    password,
  });

  return handleUserResponse(data);
};

const logout = async () => {
  removeData('access');
  removeData('token');
  removeData('activeHotel');

  return Promise.resolve(true);
};

const getUser = async () => {
  const { data } = await http.get(GET_USERS_ME);

  const activeHotelLS = getActiveHotel();

  const adminedHotels = data.adminedHotels || [];
  const managedHotels = data.managedHotels || [];
  const allHotels = uniqBy([...(adminedHotels || []), ...(managedHotels || [])], 'id');

  if (activeHotelLS) {
    const checkExist = find(allHotels, (h) => Number(h.id) === Number(activeHotelLS)) || false;

    if (!checkExist) {
      removeData('activeHotel');
    }
  } else {
    if (!isEmpty(allHotels)) {
      setData('activeHotel', `${allHotels[0].id}`);
    }
  }

  return data;
};

const updateUser = async (values) => {
  const { data } = await http.patch(PATCH_USERS_ME, values);

  return data;
};

const setPassword = async ({ newPassword, currentPassword }) => {
  const { data } = await http.post(POST_SET_PASSWORD, {
    newPassword: newPassword,
    currentPassword: currentPassword,
  });

  return data;
};

const getAccessToken = () => {
  return getData('access');
};

const getRefreshToken = () => {
  return getData('refresh');
};

const getActiveHotel = () => {
  return getData('activeHotel');
};

const isLoggedIn = () => {
  return Boolean(getAccessToken());
};

const activate = async ({ uid, token }) => {
  const { data } = await http2.post(POST_ACTIVATION, {
    uid: uid,
    token: token,
  });
  return data;
};

const resetPasswordConfirm = async ({ uid, token, newPassword }) => {
  const { data } = await http2.post(POST_RESET_PASSWORD_CONFIRM, {
    uid: uid,
    token: token,
    newPassword: newPassword,
  });
  return data;
};

export {
  login,
  logout,
  getUser,
  updateUser,
  setPassword,
  isLoggedIn,
  getAccessToken,
  getRefreshToken,
  getActiveHotel,
  activate,
  resetPasswordConfirm,
};
