import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { OrdersProvider } from './orders-context';
import { HelpersProvider } from './helpers-context';
import { GuestsProvider } from './guests-context';
import { SettingsProvider } from 'src/@core/context/settings-context';

const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <HelpersProvider>
          <OrdersProvider>
            <GuestsProvider>
              <SettingsProvider>{children}</SettingsProvider>
            </GuestsProvider>
          </OrdersProvider>
        </HelpersProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export { AppProviders };
