// ** MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = ({ size = 48 }) => {
  return (
    <Box flexGrow={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress color={'primary'} size={size} />
    </Box>
  );
};

export default Spinner;
