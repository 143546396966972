// ** Overrides
import MuiBaseline from './baseline';
import MuiButton from './button';
import MuiInput from './input';
import MuiHelperText from './helper-text';

const Overrides = () => {
  const baseline = MuiBaseline();
  const button = MuiButton();
  const input = MuiInput();
  const helperText = MuiHelperText();

  return Object.assign(baseline, button, input, helperText);
};

export default Overrides;
