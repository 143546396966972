// ** Constants
import { GET_STAYS } from 'src/constants';

// ** Helpers
import { http } from 'src/@core/helpers/http';

const getGuests = async (page = 1, token) => {
  const response = await http.get(GET_STAYS, {
    params: {
      page: page,
      page_size: 999,
      ordering: 'room__number',
    },
    cancelToken: token,
  });

  return response;
};

export { getGuests };
