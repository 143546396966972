// ** React
import { useReducer, useContext, createContext } from 'react';

// ** Clients
import * as guestsClient from 'src/@core/utils/guests-client';

const GuestsStateContext = createContext();
const GuestsDispatchContext = createContext();

export const guestsTypes = {
  SET_GUESTS_PENDING: 'guests/SET_GUESTS_PENDING',
  SET_GUESTS_ERROR: 'guests/SET_GUESTS_ERROR',
  SET_GUESTS: 'guests/SET_GUESTS',
};

const guestsDefaults = {
  pending: false,
  error: {
    status: null,
    message: null,
  },
  results: [],
  count: 0,
  next: null,
  previous: null,
};

const guestsReducer = (guests, action) => {
  switch (action.type) {
    case guestsTypes.SET_GUESTS_PENDING: {
      return {
        ...guests,
        pending: action.payload,
      };
    }
    case guestsTypes.SET_GUESTS_ERROR: {
      return {
        ...guests,
        error: action.payload,
      };
    }
    case guestsTypes.SET_GUESTS: {
      return {
        ...guests,
        results: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    }
    default: {
      return guests;
    }
  }
};

const GuestsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(guestsReducer, guestsDefaults);

  const getGuests = async (page = 1, token) => {
    dispatch({
      type: guestsTypes.SET_GUESTS_PENDING,
      payload: true,
    });
    try {
      const { data } = await guestsClient.getGuests(page, token);
      dispatch({
        type: guestsTypes.SET_GUESTS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: guestsTypes.SET_GUESTS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: guestsTypes.SET_GUESTS_PENDING,
        payload: false,
      });
    }
  };

  return (
    <GuestsStateContext.Provider value={state}>
      <GuestsDispatchContext.Provider value={{ dispatch, getGuests }}>{children}</GuestsDispatchContext.Provider>
    </GuestsStateContext.Provider>
  );
};

const useGuestsDispatch = () => {
  const context = useContext(GuestsDispatchContext);

  if (context === undefined) {
    throw new Error(`useGuestsDispatch must be used within a GuestsProvider`);
  }
  return context;
};

const useGuestsState = () => {
  const context = useContext(GuestsStateContext);

  if (context === undefined) {
    throw new Error(`useGuestsState must be used within a GuestsProvider`);
  }
  return context;
};

export { GuestsProvider, useGuestsDispatch, useGuestsState };
