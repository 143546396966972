// ** Constants
import { LS_TOKEN } from 'src/constants';

const hasJsonStructure = (str) => {
  if (typeof str !== 'string') return false;

  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);

    return type === '[object Object]' || type === '[object Array]' || type === '[object String]';
  } catch (err) {
    return false;
  }
};

export const getData = (key) => {
  const jsonValue = window.localStorage.getItem(LS_TOKEN + '.' + key);

  return jsonValue != null && hasJsonStructure(jsonValue) ? JSON.parse(jsonValue) : null;
};

export const setData = (key, value) => {
  const jsonValue = JSON.stringify(value);

  window.localStorage.setItem(LS_TOKEN + '.' + key, jsonValue);
};

export const removeData = (key) => {
  return window.localStorage.removeItem(LS_TOKEN + '.' + key);
};

const exportedObject = {
  getData,
  setData,
  removeData,
};

export default exportedObject;
