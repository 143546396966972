// ** React
import { useEffect, useState } from 'react';

// ** MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// ** React Router DOM
import { useParams } from 'react-router-dom';

// ** Components
import Spinner from 'src/@core/components/Spinner';

// ** Context
import { useAuth } from 'src/@core/context/auth-context';

// ** Assets
import LogoImg from 'src/assets/svg/logo.svg';

const classes = {
  root: {
    backgroundColor: 'yellow',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  wrapper: {
    flexGrow: 1,
    maxWidth: {
      lg: 390,
    },
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    height: {
      xs: '100%',
      lg: 'auto',
    },
    backgroundColor: 'white',
    padding: {
      xs: '24px',
      lg: '32px',
    },
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: {
      lg: '16px',
    },
    borderBottomRightRadius: {
      lg: '16px',
    },
  },
};

const Activate = () => {
  const params = useParams();
  const { activate } = useAuth();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const send = async () => {
      setLoading(true);
      try {
        await activate({
          uid: params.uid,
          token: params.token,
        });
        setSuccess('Активация выполнена успешно!');
      } catch (error) {
        setError('Произошла ошибка при активации!');
      } finally {
        setLoading(false);
      }
    };
    send();
  }, []);

  return (
    <Box sx={classes.root}>
      <Grid sx={classes.wrapper} container direction={'column'}>
        <Grid item xs={true} sx={classes.logo}>
          <Box>
            <img src={LogoImg} alt={''} />
          </Box>
        </Grid>
        <Grid item xs={true} container>
          <Grid item xs={12}>
            {loading ? (
              <Spinner />
            ) : (
              <Box sx={classes.loginBox}>
                {success ? <Typography>{success}</Typography> : error ? <Typography>{error}</Typography> : null}
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={false} lg={true}></Grid>
      </Grid>
    </Box>
  );
};

export default Activate;
