// ** React
import { useReducer, useContext, createContext } from 'react';

// ** Utils
import * as settingsClient from 'src/@core/utils/settings-client';

const SettingsStateContext = createContext();
const SettingsDispatchContext = createContext();

export const settingsTypes = {
  SET_INFO_PENDING: 'settings/SET_INFO_PENDING',
  SET_INFO_ERROR: 'settings/SET_INFO_ERROR',
  SET_INFO: 'settings/SET_INFO',
  SET_PHOTOS_PENDING: 'settings/SET_PHOTOS_PENDING',
  SET_PHOTOS_ERROR: 'settings/SET_PHOTOS_ERROR',
  SET_PHOTOS: 'settings/SET_PHOTOS',
  SET_PHOTO_DELETE_PENDING: 'settings/SET_PHOTO_DELETE_PENDING',
  SET_PHOTO_DELETE_ERROR: 'settings/SET_PHOTO_DELETE_ERROR',
  SET_PHOTO_MAIN_PENDING: 'settings/SET_PHOTO_MAIN_PENDING',
  SET_PHOTO_MAIN_ERROR: 'settings/SET_PHOTO_MAIN_ERROR',
  SET_PHOTO_UPLOAD_PENDING: 'settings/SET_PHOTO_UPLOAD_PENDING',
  SET_PHOTO_UPLOAD_ERROR: 'settings/SET_PHOTO_UPLOAD_ERROR',
  SET_CATEGORIES_PENDING: 'settings/SET_CATEGORIES_PENDING',
  SET_CATEGORIES_ERROR: 'settings/SET_CATEGORIES_ERROR',
  SET_CATEGORIES: 'settings/SET_CATEGORIES',
  SET_FILLED_CATEGORIES_PENDING: 'settings/SET_FILLED_CATEGORIES_PENDING',
  SET_FILLED_CATEGORIES_ERROR: 'settings/SET_FILLED_CATEGORIES_ERROR',
  SET_FILLED_CATEGORIES: 'settings/SET_FILLED_CATEGORIES',
  SET_FACILITIES_PENDING: 'settings/SET_FACILITIES_PENDING',
  SET_FACILITIES_ERROR: 'settings/SET_FACILITIES_ERROR',
  SET_FACILITIES: 'settings/SET_FACILITIES',
  SET_FACILITY_SELECT_PENDING: 'settings/SET_FACILITY_SELECT_PENDING',
  SET_FACILITY_SELECT_ERROR: 'settings/SET_FACILITY_SELECT_ERROR',
  SET_FACILITY_SELECT: 'settings/SET_FACILITY_SELECT',
  SET_SERVICES_PENDING: 'settings/SET_SERVICES_PENDING',
  SET_SERVICES_ERROR: 'settings/SET_SERVICES_ERROR',
  SET_SERVICES: 'settings/SET_SERVICES',
  SET_VCATEGORIES_PENDING: 'settings/SET_VCATEGORIES_PENDING',
  SET_VCATEGORIES_ERROR: 'settings/SET_VCATEGORIES_ERROR',
  SET_VCATEGORIES: 'settings/SET_VCATEGORIES',
  SET_VCATEGORY_ITEMS_PENDING: 'settings/SET_VCATEGORY_ITEMS_PENDING',
  SET_VCATEGORY_ITEMS_ERROR: 'settings/SET_VCATEGORY_ITEMS_ERROR',
  SET_VCATEGORY_ITEMS: 'settings/SET_VCATEGORY_ITEMS',
  SET_CHANNELS_TREE_PENDING: 'settings/SET_CHANNELS_TREE_PENDING',
  SET_CHANNELS_TREE_ERROR: 'settings/SET_CHANNELS_TREE_ERROR',
  SET_CHANNELS_TREE: 'settings/SET_CHANNELS_TREE',
  SET_POSITIONS_TREE_PENDING: 'settings/SET_POSITIONS_TREE_PENDING',
  SET_POSITIONS_TREE_ERROR: 'settings/SET_POSITIONS_TREE_ERROR',
  SET_POSITIONS_TREE: 'settings/POSITIONS_TREE',
  SET_CHANNEL_SUBSCRIBED_POSITIONS_PENDING: 'settings/SET_CHANNEL_SUBSCRIBED_POSITIONS_PENDING',
  SET_CHANNEL_SUBSCRIBED_POSITIONS_ERROR: 'settings/SET_CHANNEL_SUBSCRIBED_POSITIONS_ERROR',
  SET_CHANNEL_SUBSCRIBED_POSITIONS: 'settings/SET_CHANNEL_SUBSCRIBED_POSITIONS',
  SET_CHANNEL_SUBSCRIBED_USERS_PENDING: 'settings/SET_CHANNEL_SUBSCRIBED_USERS_PENDING',
  SET_CHANNEL_SUBSCRIBED_USERS_ERROR: 'settings/SET_CHANNEL_SUBSCRIBERD_USERS_ERROR',
  SET_CHANNEL_SUBSCRIBED_USERS: 'settings/SET_CHANNEL_SUBSCRIBED_USERS',
  SET_POSITION_CHANNELS_PENDING: 'settings/SET_POSITION_CHANNELS_PENDING',
  SET_POSITION_CHANNELS_ERROR: 'settings/SET_POSITION_CHANNELS_ERROR',
  SET_POSITION_CHANNELS: 'settings/SET_POSITION_CHANNELS',
  SET_ACCOUNTS_STAFF_PENDING: 'settings/ACCOUNTS_STAFF_PENDING',
  SET_ACCOUNTS_STAFF_ERROR: 'settings/ACCOUNTS_STAFF_ERROR',
  SET_ACCOUNTS_STAFF: 'settings/ACCOUNTS_STAFF',
  SET_BILLING_POINTS_PENDING: 'settings/SET_BILLING_POINTS_PENDING',
  SET_BILLING_POINTS_ERROR: 'settings/SET_BILLING_POINTS_ERROR',
  SET_BILLING_POINTS: 'settings/SET_BILLING_POINTS',
  SET_BILLING_GATEWAYS_PENDING: 'settings/SET_BILLING_GATEWAYS_PENDING',
  SET_BILLING_GATEWAYS_ERROR: 'settings/SET_BILLING_GATEWAYS_ERROR',
  SET_BILLING_GATEWAYS: 'settings/SET_BILLING_GATEWAYS',
  SET_PROMO_LANDINGS_PENDING: 'settings/SET_PROMO_LANDINGS_PENDING',
  SET_PROMO_LANDINGS_ERROR: 'settings/SET_PROMO_LANDINGS_ERROR',
  SET_PROMO_LANDINGS: 'settings/SET_PROMO_LANDINGS',
  SET_MENUS_PENDING: 'settings/SET_MENUS_PENDING',
  SET_MENUS_ERROR: 'settings/SET_MENUS_ERROR',
  SET_MENUS: 'settings/SET_MENUS',
  SET_MENU_ITEMS_PENDING: 'settings/SET_MENU_ITEMS_PENDING',
  SET_MENU_ITEMS_ERROR: 'settings/SET_MENU_ITEMS_ERROR',
  SET_MENU_ITEMS: 'settings/SET_MENU_ITEMS',
  SET_SAFETY_CATEGORIES_PENDING: 'settings/SET_SAFETY_CATEGORIES_PENDING',
  SET_SAFETY_CATEGORIES_ERROR: 'settings/SET_SAFETY_CATEGORIES_ERROR',
  SET_SAFETY_CATEGORIES: 'settings/SET_SAFETY_CATEGORIES',
  SET_SAFETY_ITEMS_PENDING: 'settings/SET_SAFETY_ITEMS_PENDING',
  SET_SAFETY_ITEMS_ERROR: 'settings/SET_SAFETY_ITEMS_ERROR',
  SET_SAFETY_ITEMS: 'settings/SET_SAFETY_ITEMS',
  SET_SIGHTS_CATEGORIES_PENDING: 'settings/SET_SIGHTS_CATEGORIES_PENDING',
  SET_SIGHTS_CATEGORIES_ERROR: 'settings/SET_SIGHTS_CATEGORIES_ERROR',
  SET_SIGHTS_CATEGORIES: 'settings/SET_SIGHTS_CATEGORIES',
  SET_SIGHTS_ITEMS_PENDING: 'settings/SET_SIGHTS_ITEMS_PENDING',
  SET_SIGHTS_ITEMS_ERROR: 'settings/SET_SIGHTS_ITEMS_ERROR',
  SET_SIGHTS_ITEMS: 'settings/SET_SIGHTS_ITEMS',
};

const settingsDefaults = {
  info: {},
  pendingInfo: false,
  errorInfo: {
    status: null,
    message: null,
  },
  photos: {
    pending: false,
    pendingDelete: false,
    pendingMain: false,
    pendingUpload: false,
    error: {
      status: null,
      message: null,
    },
    errorDelete: {
      status: null,
      message: null,
    },
    errorMain: {
      status: null,
      message: null,
    },
    errorUpload: {
      status: null,
      message: null,
    },
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  filledCategories: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  categories: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  facilities: {
    pending: false,
    pendingSelect: false,
    error: {
      status: null,
      message: null,
    },
    errorSelect: {
      status: null,
      message: null,
    },
    results: [],
  },
  services: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  vcategories: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  vcategoryItems: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  channelsTree: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: {},
  },
  positionsTree: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: {},
  },
  channelSubscribedPositions: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  channelSubscribedUsers: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  positionChannels: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  accountsStaff: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  billingPoints: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  billingGateways: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  promoLandings: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  menus: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  menuItems: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  safetyCategories: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  safetyItems: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  sightsCategories: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
  sightsItems: {
    pending: false,
    error: {
      status: null,
      message: null,
    },
    results: [],
  },
};

const settingsReducer = (settings, action) => {
  switch (action.type) {
    case settingsTypes.SET_INFO_PENDING: {
      return {
        ...settings,
        pendingInfo: action.payload,
      };
    }
    case settingsTypes.SET_INFO_ERROR: {
      return {
        ...settings,
        errorInfo: action.payload,
      };
    }
    case settingsTypes.SET_INFO: {
      return {
        ...settings,
        info: action.payload,
      };
    }
    case settingsTypes.SET_PHOTOS_PENDING: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTOS_ERROR: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTOS: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          results: action.payload.results,
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
      };
    }
    case settingsTypes.SET_PHOTO_DELETE_PENDING: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          pendingDelete: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTO_DELETE_ERROR: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          errorDelete: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTO_MAIN_PENDING: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          pendingMain: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTO_MAIN_ERROR: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          errorMain: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTO_UPLOAD_PENDING: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          pendingUpload: action.payload,
        },
      };
    }
    case settingsTypes.SET_PHOTO_UPLOAD_ERROR: {
      return {
        ...settings,
        photos: {
          ...settings.photos,
          errorUpload: action.payload,
        },
      };
    }
    case settingsTypes.SET_CATEGORIES_PENDING: {
      return {
        ...settings,
        categories: {
          ...settings.categories,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_CATEGORIES_ERROR: {
      return {
        ...settings,
        categories: {
          ...settings.categories,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_CATEGORIES: {
      return {
        ...settings,
        categories: {
          ...settings.categories,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_FILLED_CATEGORIES_PENDING: {
      return {
        ...settings,
        filledCategories: {
          ...settings.filledCategories,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_FILLED_CATEGORIES_ERROR: {
      return {
        ...settings,
        filledCategories: {
          ...settings.filledCategories,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_FILLED_CATEGORIES: {
      return {
        ...settings,
        filledCategories: {
          ...settings.filledCategories,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITIES_PENDING: {
      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITIES_ERROR: {
      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITIES: {
      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITY_SELECT_PENDING: {
      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          pendingSelect: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITY_SELECT_ERROR: {
      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          errorSelect: action.payload,
        },
      };
    }
    case settingsTypes.SET_FACILITY_SELECT: {
      let results = settings.facilities.results;
      const item = action.payload;
      const foundIndex = results.findIndex((x) => x.id === item.id);
      if (foundIndex > -1) results[foundIndex] = item;

      return {
        ...settings,
        facilities: {
          ...settings.facilities,
          results: results,
        },
      };
    }
    case settingsTypes.SET_SERVICES_PENDING: {
      return {
        ...settings,
        services: {
          ...settings.services,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_SERVICES_ERROR: {
      return {
        ...settings,
        services: {
          ...settings.services,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_SERVICES: {
      return {
        ...settings,
        services: {
          ...settings.services,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORIES_PENDING: {
      return {
        ...settings,
        vcategories: {
          ...settings.vcategories,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORIES_ERROR: {
      return {
        ...settings,
        vcategories: {
          ...settings.vcategories,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORIES: {
      return {
        ...settings,
        vcategories: {
          ...settings.vcategories,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORY_ITEMS_PENDING: {
      return {
        ...settings,
        vcategoryItems: {
          ...settings.vcategoryItems,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORY_ITEMS_ERROR: {
      return {
        ...settings,
        vcategoryItems: {
          ...settings.vcategoryItems,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_VCATEGORY_ITEMS: {
      return {
        ...settings,
        vcategoryItems: {
          ...settings.vcategoryItems,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNELS_TREE_PENDING: {
      return {
        ...settings,
        channelsTree: {
          ...settings.channelsTree,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNELS_TREE_ERROR: {
      return {
        ...settings,
        channelsTree: {
          ...settings.channelsTree,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNELS_TREE: {
      return {
        ...settings,
        channelsTree: {
          ...settings.channelsTree,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITIONS_TREE_PENDING: {
      return {
        ...settings,
        positionsTree: {
          ...settings.positionsTree,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITIONS_TREE_ERROR: {
      return {
        ...settings,
        positionsTree: {
          ...settings.positionsTree,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITIONS_TREE: {
      return {
        ...settings,
        positionsTree: {
          ...settings.positionsTree,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_PENDING: {
      return {
        ...settings,
        channelSubscribedPositions: {
          ...settings.channelSubscribedPositions,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_ERROR: {
      return {
        ...settings,
        channelSubscribedPositions: {
          ...settings.channelSubscribedPositions,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS: {
      return {
        ...settings,
        channelSubscribedPositions: {
          ...settings.channelSubscribedPositions,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_PENDING: {
      return {
        ...settings,
        channelSubscribedUsers: {
          ...settings.channelSubscribedUsers,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_ERROR: {
      return {
        ...settings,
        channelSubscribedUsers: {
          ...settings.channelSubscribedUsers,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS: {
      return {
        ...settings,
        channelSubscribedUsers: {
          ...settings.channelSubscribedUsers,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITION_CHANNELS_PENDING: {
      return {
        ...settings,
        positionChannels: {
          ...settings.positionChannels,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITION_CHANNELS_ERROR: {
      return {
        ...settings,
        positionChannels: {
          ...settings.positionChannels,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_POSITION_CHANNELS: {
      return {
        ...settings,
        positionChannels: {
          ...settings.positionChannels,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_ACCOUNTS_STAFF_PENDING: {
      return {
        ...settings,
        accountsStaff: {
          ...settings.accountsStaff,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_ACCOUNTS_STAFF_ERROR: {
      return {
        ...settings,
        accountsStaff: {
          ...settings.accountsStaff,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_ACCOUNTS_STAFF: {
      return {
        ...settings,
        accountsStaff: {
          ...settings.accountsStaff,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_POINTS_PENDING: {
      return {
        ...settings,
        billingPoints: {
          ...settings.billingPoints,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_POINTS_ERROR: {
      return {
        ...settings,
        billingPoints: {
          ...settings.billingPoints,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_POINTS: {
      return {
        ...settings,
        billingPoints: {
          ...settings.billingPoints,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_GATEWAYS_PENDING: {
      return {
        ...settings,
        billingGateways: {
          ...settings.billingGateways,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_GATEWAYS_ERROR: {
      return {
        ...settings,
        billingGateways: {
          ...settings.billingGateways,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_BILLING_GATEWAYS: {
      return {
        ...settings,
        billingGateways: {
          ...settings.billingGateways,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_PROMO_LANDINGS_PENDING: {
      return {
        ...settings,
        promoLandings: {
          ...settings.promoLandings,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_PROMO_LANDINGS_ERROR: {
      return {
        ...settings,
        promoLandings: {
          ...settings.promoLandings,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_PROMO_LANDINGS: {
      return {
        ...settings,
        promoLandings: {
          ...settings.promoLandings,
          results: action.payload.results,
        },
      };
    }
    case settingsTypes.SET_MENUS_PENDING: {
      return {
        ...settings,
        menus: {
          ...settings.menus,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_MENUS_ERROR: {
      return {
        ...settings,
        menus: {
          ...settings.menus,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_MENUS: {
      return {
        ...settings,
        menus: {
          ...settings.menus,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_MENU_ITEMS_PENDING: {
      return {
        ...settings,
        menuItems: {
          ...settings.menuItems,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_MENU_ITEMS_ERROR: {
      return {
        ...settings,
        menuItems: {
          ...settings.menuItems,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_MENU_ITEMS: {
      return {
        ...settings,
        menuItems: {
          ...settings.menuItems,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_CATEGORIES_PENDING: {
      return {
        ...settings,
        safetyCategories: {
          ...settings.safetyCategories,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_CATEGORIES_ERROR: {
      return {
        ...settings,
        safetyCategories: {
          ...settings.safetyCategories,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_CATEGORIES: {
      return {
        ...settings,
        safetyCategories: {
          ...settings.safetyCategories,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_ITEMS_PENDING: {
      return {
        ...settings,
        safetyItems: {
          ...settings.safetyItems,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_ITEMS_ERROR: {
      return {
        ...settings,
        safetyItems: {
          ...settings.safetyItems,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_SAFETY_ITEMS: {
      return {
        ...settings,
        safetyItems: {
          ...settings.safetyItems,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_CATEGORIES_PENDING: {
      return {
        ...settings,
        sightsCategories: {
          ...settings.sightsCategories,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_CATEGORIES_ERROR: {
      return {
        ...settings,
        sightsCategories: {
          ...settings.sightsCategories,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_CATEGORIES: {
      return {
        ...settings,
        sightsCategories: {
          ...settings.sightsCategories,
          results: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_ITEMS_PENDING: {
      return {
        ...settings,
        sightsItems: {
          ...settings.sightsItems,
          pending: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_ITEMS_ERROR: {
      return {
        ...settings,
        sightsItems: {
          ...settings.sightsItems,
          error: action.payload,
        },
      };
    }
    case settingsTypes.SET_SIGHTS_ITEMS: {
      return {
        ...settings,
        sightsItems: {
          ...settings.sightsItems,
          results: action.payload,
        },
      };
    }
    default: {
      return settings;
    }
  }
};

const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(settingsReducer, settingsDefaults);

  const getInfo = async (id) => {
    dispatch({
      type: settingsTypes.SET_INFO_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_INFO_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getHotel(id);
      dispatch({
        type: settingsTypes.SET_INFO,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_INFO_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_INFO_PENDING,
        payload: false,
      });
    }
  };

  const updateInfo = async (id, values) => {
    const response = await settingsClient.updateHotel(id, values);
    dispatch({
      type: settingsTypes.SET_INFO,
      payload: response.data,
    });
  };

  const getPhotos = async (type, id, page) => {
    dispatch({
      type: settingsTypes.SET_PHOTOS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_PHOTOS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getPhotos(type, id, page);
      dispatch({
        type: settingsTypes.SET_PHOTOS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_PHOTOS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_PHOTOS_PENDING,
        payload: false,
      });
    }
  };

  const deletePhoto = async (id) => {
    dispatch({
      type: settingsTypes.SET_PHOTO_DELETE_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_PHOTO_DELETE_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.deletePhoto(id);

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_PHOTO_DELETE_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_PHOTO_DELETE_PENDING,
        payload: false,
      });
    }
  };

  const setMainPhoto = async (id) => {
    dispatch({
      type: settingsTypes.SET_PHOTO_MAIN_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_PHOTO_MAIN_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.setMainPhoto(id);

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_PHOTO_MAIN_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_PHOTO_MAIN_PENDING,
        payload: false,
      });
    }
  };

  const uploadPhoto = async (type, id, image) => {
    dispatch({
      type: settingsTypes.SET_PHOTO_UPLOAD_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_PHOTO_UPLOAD_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.uploadPhoto(type, id, image);

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_PHOTO_UPLOAD_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_PHOTO_UPLOAD_PENDING,
        payload: false,
      });
    }
  };

  const getCategories = async () => {
    dispatch({
      type: settingsTypes.SET_CATEGORIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_CATEGORIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getCategories();
      dispatch({
        type: settingsTypes.SET_CATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_CATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_CATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  const getFilledCategories = async () => {
    dispatch({
      type: settingsTypes.SET_FILLED_CATEGORIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FILLED_CATEGORIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getFilledCategories();
      dispatch({
        type: settingsTypes.SET_FILLED_CATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FILLED_CATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FILLED_CATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  const getFacilities = async ({ category }) => {
    dispatch({
      type: settingsTypes.SET_FACILITIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FACILITIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getFacilities({ category });
      dispatch({
        type: settingsTypes.SET_FACILITIES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FACILITIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FACILITIES_PENDING,
        payload: false,
      });
    }
  };

  const selectFacility = async (id) => {
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.selectFacility(id);
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT,
        payload: response.data,
      });

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_PENDING,
        payload: false,
      });
    }
  };

  const unselectFacility = async (id) => {
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.unselectFacility(id);
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT,
        payload: response.data,
      });

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_PENDING,
        payload: false,
      });
    }
  };

  const linkFacility = async (id) => {
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.linkFacility(id);
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT,
        payload: response.data,
      });

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_PENDING,
        payload: false,
      });
    }
  };

  const unlinkFacility = async (id) => {
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_FACILITY_SELECT_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const response = await settingsClient.unlinkFacility(id);
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT,
        payload: response.data,
      });

      return response;
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_FACILITY_SELECT_PENDING,
        payload: false,
      });
    }
  };

  const getServices = async (page, token) => {
    dispatch({
      type: settingsTypes.SET_SERVICES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_SERVICES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getServices(page, token);
      dispatch({
        type: settingsTypes.SET_SERVICES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_SERVICES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_SERVICES_PENDING,
        payload: false,
      });
    }
  };

  const addService = async (values) => {
    const response = await settingsClient.addService(values);

    return response;
  };

  const editService = async (id, values) => {
    const response = await settingsClient.editService(id, values);

    return response;
  };

  const deleteService = async (id) => {
    const response = await settingsClient.deleteService(id);

    return response;
  };

  const getVcategories = async (page) => {
    dispatch({
      type: settingsTypes.SET_VCATEGORIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_VCATEGORIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getVcategories(page);
      dispatch({
        type: settingsTypes.SET_VCATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_VCATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_VCATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  const addVcategory = async (values) => {
    const response = await settingsClient.addVcategory(values);

    return response;
  };

  const editVcategory = async (id, values) => {
    const response = await settingsClient.editVcategory(id, values);

    return response;
  };

  const deleteVcategory = async (id) => {
    const response = await settingsClient.deleteVcategory(id);

    return response;
  };

  const getVcategoryItems = async (vcategoryId, page) => {
    dispatch({
      type: settingsTypes.SET_VCATEGORY_ITEMS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_VCATEGORY_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getVcategoryItems(vcategoryId, page);
      dispatch({
        type: settingsTypes.SET_VCATEGORY_ITEMS,
        payload: data.results,
      });

      return Promise.resolve(data.results);
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_VCATEGORY_ITEMS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });

      return Promise.reject(e);
    } finally {
      dispatch({
        type: settingsTypes.SET_VCATEGORY_ITEMS_PENDING,
        payload: false,
      });
    }
  };

  const resetVcategoryItems = async () => {
    dispatch({
      type: settingsTypes.SET_VCATEGORY_ITEMS_PENDING,
      payload: false,
    });
    dispatch({
      type: settingsTypes.SET_VCATEGORY_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    dispatch({
      type: settingsTypes.SET_VCATEGORY_ITEMS,
      payload: [],
    });
  };

  const modifyVcategoryItems = async ({ vcategoryId, createItems = [], editItems = [], deleteItems = [] }) => {
    const createPromises = createItems.map(async (createItem) => {
      try {
        const { data } = await settingsClient.addVcategoryItem({
          virtual_category: vcategoryId,
          service: createItem.service,
          name: createItem.name,
          icon: createItem.icon || 'None',
        });

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const editPromises = editItems.map(async (editItem) => {
      try {
        const { data } = await settingsClient.editVcategoryItem(editItem.id, {
          service: editItem.service,
          name: editItem.name,
          icon: editItem.icon || 'None',
        });

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const deletePromises = deleteItems.map(async (deleteItem) => {
      try {
        const { data } = await settingsClient.deleteVcategoryItem(deleteItem.id);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const promises = await Promise.all([...createPromises, ...editPromises, ...deletePromises]);

    return promises;
  };

  const sortVcategoryItems = async (vcategoryId, items) => {
    if (items.length === 0) return Promise.resolve();

    try {
      const { data } = await settingsClient.getVcategoryItems(vcategoryId);
      const actualItems = data.results;

      const sortItems = [];

      for (let i = 0; i < items.length; i++) {
        const checkItem = actualItems.find((d) => d.service === items[i].service);
        sortItems.push({
          id: checkItem.id,
          order: i + 1,
        });
      }

      const response = await settingsClient.sortVcategoryItems(sortItems);

      return Promise.resolve(response);
    } catch (e) {
      Promise.resolve(e);
    }
  };

  const getChannelsTree = async () => {
    dispatch({
      type: settingsTypes.SET_CHANNELS_TREE_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_CHANNELS_TREE_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getChannelsTree();
      dispatch({
        type: settingsTypes.SET_CHANNELS_TREE,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_CHANNELS_TREE_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_CHANNELS_TREE_PENDING,
        payload: false,
      });
    }
  };

  const getPositionsTree = async () => {
    dispatch({
      type: settingsTypes.SET_POSITIONS_TREE_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_POSITIONS_TREE_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getPositionsTree();
      dispatch({
        type: settingsTypes.SET_POSITIONS_TREE,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_POSITIONS_TREE_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_POSITIONS_TREE_PENDING,
        payload: false,
      });
    }
  };

  const addChannelsChildNode = async (id, values) => {
    const response = await settingsClient.addChannelsChildNode(id, values);

    return response;
  };

  const addPositionsChildNode = async (id, values) => {
    const response = await settingsClient.addPositionsChildNode(id, values);

    return response;
  };

  const renameChannel = async (id, values) => {
    const response = await settingsClient.renameChannel(id, values);

    return response;
  };

  const renamePosition = async (id, values) => {
    const response = await settingsClient.renamePosition(id, values);

    return response;
  };

  const deleteChannel = async (id) => {
    const response = await settingsClient.deleteChannel(id);

    return response;
  };

  const deletePosition = async (id) => {
    const response = await settingsClient.deletePosition(id);

    return response;
  };

  const getChannelSubscribedPositions = async (id) => {
    dispatch({
      type: settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getChannelSubscribedPositions(id);
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_POSITIONS_PENDING,
        payload: false,
      });
    }
  };

  const getChannelSubscribedUsers = async (id) => {
    dispatch({
      type: settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getChannelSubscribedUsers(id);
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_CHANNEL_SUBSCRIBED_USERS_PENDING,
        payload: false,
      });
    }
  };

  const getPositionChannels = async (id) => {
    dispatch({
      type: settingsTypes.SET_POSITION_CHANNELS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_POSITION_CHANNELS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getPositionChannels(id);
      dispatch({
        type: settingsTypes.SET_POSITION_CHANNELS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_POSITION_CHANNELS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_POSITION_CHANNELS_PENDING,
        payload: false,
      });
    }
  };

  const getAccountsStaff = async () => {
    dispatch({
      type: settingsTypes.SET_ACCOUNTS_STAFF_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_ACCOUNTS_STAFF_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getAccountsStaff();
      dispatch({
        type: settingsTypes.SET_ACCOUNTS_STAFF,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_ACCOUNTS_STAFF_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_ACCOUNTS_STAFF_PENDING,
        payload: false,
      });
    }
  };

  const addAccountsStaff = async (values) => {
    const response = await settingsClient.addAccountsStaff(values);

    return response;
  };

  const editAccountsStaff = async (id, values) => {
    const response = await settingsClient.editAccountsStaff(id, values);

    return response;
  };

  const deactivateAccountsStaff = async (id, values) => {
    const response = await settingsClient.deactivateAccountsStaff(id, values);

    return response;
  };

  const subscribeUserToChannel = async (id, userId) => {
    const response = await settingsClient.subscribeUserToChannel(id, userId);

    return response;
  };

  const subscribePositionToChannel = async (id, positionId) => {
    const response = await settingsClient.subscribePositionToChannel(id, positionId);

    return response;
  };

  const unsubscribeUserFromChannel = async (id, userId) => {
    const response = await settingsClient.unsubscribeUserFromChannel(id, userId);

    return response;
  };

  const unsubscribePositionFromChannel = async (id, positionId) => {
    const response = await settingsClient.unsubscribePositionFromChannel(id, positionId);

    return response;
  };

  const addPositionToUser = async (id, userId) => {
    const response = await settingsClient.addPositionToUser(id, userId);

    return response;
  };

  const removePositionFromUser = async (id, userId) => {
    const response = await settingsClient.removePositionFromUser(id, userId);

    return response;
  };

  const getBillingPoints = async () => {
    dispatch({
      type: settingsTypes.SET_BILLING_POINTS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_BILLING_POINTS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getBillingPoints();
      dispatch({
        type: settingsTypes.SET_BILLING_POINTS,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_BILLING_POINTS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_BILLING_POINTS_PENDING,
        payload: false,
      });
    }
  };

  const getBillingGateways = async () => {
    dispatch({
      type: settingsTypes.SET_BILLING_GATEWAYS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_BILLING_GATEWAYS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getBillingGateways();
      dispatch({
        type: settingsTypes.SET_BILLING_GATEWAYS,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_BILLING_GATEWAYS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_BILLING_GATEWAYS_PENDING,
        payload: false,
      });
    }
  };

  const addBillingPoint = async (values) => {
    const response = await settingsClient.addBillingPoint(values);

    return response;
  };

  const getPromoLandings = async (page) => {
    dispatch({
      type: settingsTypes.SET_PROMO_LANDINGS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_PROMO_LANDINGS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getPromoLandings(page);
      dispatch({
        type: settingsTypes.SET_PROMO_LANDINGS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_PROMO_LANDINGS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_PROMO_LANDINGS_PENDING,
        payload: false,
      });
    }
  };

  const addPromoLanding = async (values) => {
    const response = await settingsClient.addPromoLanding(values);

    return response;
  };

  const editPromoLanding = async (id, values) => {
    const response = await settingsClient.editPromoLanding(id, values);

    return response;
  };

  const deletePromoLanding = async (id) => {
    const response = await settingsClient.deletePromoLanding(id);

    return response;
  };

  const getMenus = async (hotel, page) => {
    dispatch({
      type: settingsTypes.SET_MENUS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_MENUS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getMenus(hotel, page);
      dispatch({
        type: settingsTypes.SET_MENUS,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_MENUS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_MENUS_PENDING,
        payload: false,
      });
    }
  };

  const addMenu = async (values) => {
    const response = await settingsClient.addMenu(values);

    return response;
  };

  const editMenu = async (id, values) => {
    const response = await settingsClient.editMenu(id, values);

    return response;
  };

  const deleteMenu = async (id) => {
    const response = await settingsClient.deleteMenu(id);

    return response;
  };

  const getMenuItems = async (menuId, page) => {
    dispatch({
      type: settingsTypes.SET_MENU_ITEMS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_MENU_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getMenuItems(menuId, page);
      dispatch({
        type: settingsTypes.SET_MENU_ITEMS,
        payload: data.results,
      });

      return Promise.resolve(data.results);
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_MENU_ITEMS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });

      return Promise.reject(e);
    } finally {
      dispatch({
        type: settingsTypes.SET_MENU_ITEMS_PENDING,
        payload: false,
      });
    }
  };

  const resetMenuItems = async () => {
    dispatch({
      type: settingsTypes.SET_MENU_ITEMS_PENDING,
      payload: false,
    });
    dispatch({
      type: settingsTypes.SET_MENU_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    dispatch({
      type: settingsTypes.SET_MENU_ITEMS,
      payload: [],
    });
  };

  const modifyMenuItems = async ({ menuId, createItems = [], editItems = [], deleteItems = [] }) => {
    const createPromises = createItems.map(async (createItem) => {
      try {
        const { data } = await settingsClient.addMenuItem({
          menu: menuId,
          c_content_type: createItem.type,
          object_id: createItem.objectId,
        });

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const editPromises = editItems.map(async (editItem) => {
      try {
        const { data } = await settingsClient.editMenuItem(editItem.id, {
          c_content_type: editItem.type,
          object_id: editItem.objectId,
        });

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const deletePromises = deleteItems.map(async (deleteItem) => {
      try {
        const { data } = await settingsClient.deleteMenuItem(deleteItem.id);

        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error);
      }
    });

    const promises = await Promise.all([...createPromises, ...editPromises, ...deletePromises]);

    return promises;
  };

  const sortMenuItems = async (menuId, items) => {
    if (items.length === 0) return Promise.resolve();

    try {
      const { data } = await settingsClient.getMenuItems(menuId);
      const actualItems = data.results;

      const sortItems = [];

      for (let i = 0; i < items.length; i++) {
        const checkItem = actualItems.find((d) => d.object.id === items[i].objectId);
        sortItems.push({
          id: checkItem.id,
          order: i + 1,
        });
      }

      const response = await settingsClient.sortMenuItems(sortItems);

      return Promise.resolve(response);
    } catch (e) {
      Promise.resolve(e);
    }
  };

  const getSafetyCategories = async () => {
    dispatch({
      type: settingsTypes.SET_SAFETY_CATEGORIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_SAFETY_CATEGORIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getSafetyCategories();
      dispatch({
        type: settingsTypes.SET_SAFETY_CATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_SAFETY_CATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_SAFETY_CATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  const getSafetyItems = async ({ category }) => {
    dispatch({
      type: settingsTypes.SET_SAFETY_ITEMS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_SAFETY_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getSafetyItems({ category });
      dispatch({
        type: settingsTypes.SET_SAFETY_ITEMS,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_SAFETY_ITEMS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_SAFETY_ITEMS_PENDING,
        payload: false,
      });
    }
  };

  const addSafetyItem = async (values) => {
    const response = await settingsClient.addSafetyItem(values);

    return response;
  };

  const editSafetyItem = async (id, values) => {
    const response = await settingsClient.editSafetyItem(id, values);

    return response;
  };

  const deleteSafetyItem = async (id) => {
    const response = await settingsClient.deleteSafetyItem(id);

    return response;
  };

  const getSightsCategories = async () => {
    dispatch({
      type: settingsTypes.SET_SIGHTS_CATEGORIES_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_SIGHTS_CATEGORIES_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getSightsCategories();
      dispatch({
        type: settingsTypes.SET_SIGHTS_CATEGORIES,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_SIGHTS_CATEGORIES_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_SIGHTS_CATEGORIES_PENDING,
        payload: false,
      });
    }
  };

  const getSightsItems = async ({ category }) => {
    dispatch({
      type: settingsTypes.SET_SIGHTS_ITEMS_PENDING,
      payload: true,
    });
    dispatch({
      type: settingsTypes.SET_SIGHTS_ITEMS_ERROR,
      payload: {
        status: null,
        message: null,
      },
    });
    try {
      const { data } = await settingsClient.getSightsItems({ category });
      dispatch({
        type: settingsTypes.SET_SIGHTS_ITEMS,
        payload: data.results,
      });
    } catch (e) {
      dispatch({
        type: settingsTypes.SET_SIGHTS_ITEMS_ERROR,
        payload: {
          status: e.response?.status || 0,
          message: e.response?.statusText || 'Произошла ошибка',
        },
      });
    } finally {
      dispatch({
        type: settingsTypes.SET_SIGHTS_ITEMS_PENDING,
        payload: false,
      });
    }
  };

  const addSightsItem = async (values) => {
    const response = await settingsClient.addSightsItem(values);

    return response;
  };

  const editSightsItem = async (id, values) => {
    const response = await settingsClient.editSightsItem(id, values);

    return response;
  };

  const deleteSightsItem = async (id) => {
    const response = await settingsClient.deleteSightsItem(id);

    return response;
  };

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider
        value={{
          dispatch,
          getInfo,
          updateInfo,
          getPhotos,
          deletePhoto,
          setMainPhoto,
          uploadPhoto,
          getCategories,
          getFilledCategories,
          getFacilities,
          selectFacility,
          unselectFacility,
          linkFacility,
          unlinkFacility,
          getServices,
          addService,
          editService,
          deleteService,
          getVcategories,
          addVcategory,
          editVcategory,
          deleteVcategory,
          getVcategoryItems,
          resetVcategoryItems,
          modifyVcategoryItems,
          sortVcategoryItems,
          getChannelsTree,
          getPositionsTree,
          addChannelsChildNode,
          addPositionsChildNode,
          deleteChannel,
          deletePosition,
          renameChannel,
          renamePosition,
          getChannelSubscribedPositions,
          getChannelSubscribedUsers,
          getPositionChannels,
          getAccountsStaff,
          addAccountsStaff,
          editAccountsStaff,
          deactivateAccountsStaff,
          subscribeUserToChannel,
          subscribePositionToChannel,
          unsubscribeUserFromChannel,
          unsubscribePositionFromChannel,
          addPositionToUser,
          removePositionFromUser,
          getBillingPoints,
          getBillingGateways,
          addBillingPoint,
          getPromoLandings,
          addPromoLanding,
          editPromoLanding,
          deletePromoLanding,
          getMenus,
          addMenu,
          editMenu,
          deleteMenu,
          getMenuItems,
          resetMenuItems,
          modifyMenuItems,
          sortMenuItems,
          getSafetyCategories,
          getSafetyItems,
          addSafetyItem,
          editSafetyItem,
          deleteSafetyItem,
          getSightsCategories,
          getSightsItems,
          addSightsItem,
          editSightsItem,
          deleteSightsItem,
        }}
      >
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
};

const useSettingsDispatch = () => {
  const context = useContext(SettingsDispatchContext);

  if (context === undefined) {
    throw new Error(`useSettingsDispatch must be used within a SettingsProvider`);
  }
  return context;
};

const useSettingsState = () => {
  const context = useContext(SettingsStateContext);

  if (context === undefined) {
    throw new Error(`useSettingsState must be used within a SettingsProvider`);
  }
  return context;
};

export { SettingsProvider, useSettingsDispatch, useSettingsState };
