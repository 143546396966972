// ** Constants
import { GET_SERVICE_ORDERS_REPORT } from 'src/constants';

// ** Helpers
import { http } from 'src/@core/helpers/http';

const getMenu = async () => {
  const response = await http.get(GET_SERVICE_ORDERS_REPORT);

  return response;
};

export { getMenu };
