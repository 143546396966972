// ** Lodash
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

// ** Utils
import * as authClient from 'src/@core/utils/auth-client';

// ** Helpers
import { getData } from 'src/@core/helpers/storage';

const processAppData = async () => {
  let appData = {
    user: null,
    activeHotel: null,
    isManagedHotel: false,
    isAdminedHotel: false,
  };

  if (authClient.isLoggedIn()) {
    try {
      const user = await authClient.getUser();

      const activeHotelLS = getData('activeHotel');

      const adminedHotels = user.adminedHotels || [];
      const managedHotels = user.managedHotels || [];
      const allHotels = uniqBy([...(adminedHotels || []), ...(managedHotels || [])], 'id');

      let activeHotel = null;
      let isAdminedHotel = false;
      let isManagedHotel = false;

      if (activeHotelLS) {
        const checkExist = find(allHotels, (h) => Number(h.id) === Number(activeHotelLS)) || false;
        if (checkExist) {
          activeHotel = checkExist.id;
        }
      } else {
        if (!isEmpty(allHotels)) {
          activeHotel = allHotels[0].id;
        }
      }

      if (activeHotel) {
        const checkAdmined = find(adminedHotels, (a) => Number(a.id) === Number(activeHotel)) || false;
        const checkManaged = find(managedHotels, (a) => Number(a.id) === Number(activeHotel)) || false;

        isAdminedHotel = !!checkAdmined;
        isManagedHotel = !!checkManaged;
      }

      appData = {
        user,
        activeHotel: activeHotel,
        isManagedHotel: isManagedHotel,
        isAdminedHotel: isAdminedHotel,
      };
    } catch (error) {
      authClient.logout();
    }
  }

  return appData;
};

export { processAppData };
