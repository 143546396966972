// ** MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// ** Theme Override
import spacing from './spacing';
import overrides from './overrides';

const ThemeComponent = ({ children }) => {
  const coreThemeConfig = {
    palette: {
      black: '#3D3D3D',
      white: '#FFFFFF',
      alert: '#DC4E5C',
      alertLight: '#F1B3A7',
      border: '#CCCCCC',
      bg: '#EBEBEB',
      gray: '#A6A6A6',
      textLight: '#ABACAE',
      yellow: '#F7EB5B',
      blue: '#4BA8B3',
    },
    ...spacing,
    typography: {
      fontFamily: ['"Ubuntu"', 'sans-serif'].join(','),
    },
  };

  let theme = createTheme(coreThemeConfig);

  theme = createTheme(theme, {
    components: { ...overrides(theme) },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
